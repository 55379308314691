@font-face {
  font-family: "BrutalType-Medium";
  font-weight: medium;
  font-style: normal;
  src: url("../components/assets/fonts/BrutalType/BrutalType-Medium.ttf");
}

:root {
  --ion-color-primary: #fed700;
  --ion-color-primary-rgb: 81, 104, 143;
  --ion-color-primary-contrast: #333e48;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  /* --ion-color-primary-shade: #e5ca2e; */
  --ion-color-primary-shade: #fed700;
  --ion-color-primary-tint: #e9c602;
  --ion-toolbar-color: #333e48;

  --ion-color-secondary: #557f5f;
  --ion-color-secondary-rgb: 85, 127, 95;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #4b7054;
  --ion-color-secondary-tint: #668c6f;

  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  --ion-color-success: rgb(61, 179, 61);
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  --ion-color-danger: #f53d3d;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d83636;
  --ion-color-danger-tint: #f65050;

  --ion-color-dark: #212121;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1d1d1d;
  --ion-color-dark-tint: #373737;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #fafafa;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #dcdcdc;
  --ion-color-light-tint: #fbfbfb;
  --border-radius-value: 25px;
  --border-radius-product-value: 20px;
  --border-radius-card-value: 12px;
  --border-radius-toolbar-value: 0px 0px 12px 12px;
  --border-radius-input-value: 22px;
  --transform-scale-value: none;
  --background-color-value: #fed700;
  --ion-toolbar-background: #fed700;
  --toolbal-background-color-value: #fed700;
  --border-radius-button-value: 40px;
  --ion-color-new-product: var(--ion-color-danger);
  --ion-color-sections-value: #333e48;
  --ion-card-border-value: 1px solid transparent;
  --border-product-new-list: none;
  --border-product-new-list-bottom: 1px solid rgba(169, 165, 153, 0.15);
  --border-radius-new-list-value: 0;
  --border-shadow-new-list-product: none;
  --category-icon-size: auto;
  --category-icon-padding: 10px 10px 0 10px;
  --home-page-category-size: 80px;
}

:root {
  font-size: 16px;
  --accent-color: #dddddd;
  --heading-font-size: 0.8rem;
  --sub-heading-font-size: 0.6875rem;
  --ion-color-facebook: #3b5998;
  --ion-color-star: #d9d027;
  --ion-color-gray: gray;
  --ion-margin: 10px;
  --ion-padding: 10px;
}

/*
//  * other Themes
//  * ----------------------------------------------------------------------------
//  */
.dark-theme {
  --ion-background-color: #464646;
  --ion-background-color-rgb: 18, 18, 18;
  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;
  /* --ion-border-color: #222222;
   --ion-item-background: #1a1b1e; */
}
.light-theme {
  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 255, 255, 255;
  --ion-text-color: #000000;
  --ion-text-color-rgb: 0, 0, 0;
  /* --ion-border-color: #ffffff;
   --ion-item-background: #ffffff; */
}
.white-theme {
  --ion-color-primary: #ffffff;
  --ion-color-primary-rgb: 255, 255, 255;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #e0e0e0;
  --ion-color-primary-tint: #ffffff;
}
/*
  //  * other Themes
  //  * ----------------------------------------------------------------------------
  //  */
.black-theme {
  --ion-color-primary: black;
  --ion-color-primary-rgb: black;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: black;
  --ion-color-primary-tint: black;
}
.green-theme {
  --ion-color-primary: #ec3f34;
  --ion-color-primary-rgb: 236, 63, 52;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #d0372e;
  --ion-color-primary-tint: #ee5248;
}
.plum-theme {
  --ion-color-primary: #44b3ff;
  --ion-color-primary-rgb: 68, 179, 255;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #3c9ee0;
  --ion-color-primary-tint: #57bbff;
}

.magnesium-theme {
  --ion-color-primary: #fcad8e;
  --ion-color-primary-rgb: 252, 173, 142;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #de987d;
  --ion-color-primary-tint: #fcb599;
}
.salmon-theme {
  --ion-color-primary: #ff8ea6;
  --ion-color-primary-rgb: 255, 142, 166;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #e07d92;
  --ion-color-primary-tint: #ff99af;
}

.blue-theme {
  --ion-color-primary: #9546fe;
  --ion-color-primary-rgb: 149, 70, 254;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #833ee0;
  --ion-color-primary-tint: #a059fe;
}

.pink-theme {
  --ion-color-primary: #a6633c;
  --ion-color-primary-rgb: 166, 99, 60;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #925735;
  --ion-color-primary-tint: #af7350;
}

.orange-theme {
  --ion-color-primary: #3ca68d;
  --ion-color-primary-rgb: 60, 166, 141;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #35927c;
  --ion-color-primary-tint: #50af98;
}

.maroon-theme {
  --ion-color-primary: #3c51a6;
  --ion-color-primary-rgb: 60, 81, 166;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #354792;
  --ion-color-primary-tint: #5062af;
}

.cayanne-theme {
  --ion-color-primary: #726dff;
  --ion-color-primary-rgb: 114, 109, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #6460e0;
  --ion-color-primary-tint: #807cff;
}

.red-theme {
  --ion-color-primary: #bf04a0;
  --ion-color-primary-rgb: 191, 4, 160;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #a8048d;
  --ion-color-primary-tint: #c51daa;
}

.grape-theme {
  --ion-color-primary: #9437ff;
  --ion-color-primary-rgb: 148, 55, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #8230e0;
  --ion-color-primary-tint: #9f4bff;
}

.sky-theme {
  --ion-color-primary: #76d6ff;
  --ion-color-primary-rgb: 118, 214, 255;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #68bce0;
  --ion-color-primary-tint: #84daff;
}

.sea-theme {
  --ion-color-primary: #ff6d6d;
  --ion-color-primary-rgb: 255, 109, 109;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #e06060;
  --ion-color-primary-tint: #ff7c7c;
}

.theme15-theme {
  --ion-color-primary: #b3182a;
  --ion-color-primary-rgb: 179, 24, 42;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #9e1525;
  --ion-color-primary-tint: #bb2f3f;
}
.theme16-theme {
  --ion-color-primary: #3e5902;
  --ion-color-primary-rgb: 62, 89, 2;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #374e02;
  --ion-color-primary-tint: #516a1b;
}
.theme17-theme {
  --ion-color-primary: #483a6f;
  --ion-color-primary-rgb: 72, 58, 111;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3f3362;
  --ion-color-primary-tint: #5a4e7d;
}
.theme18-theme {
  --ion-color-primary: #621529;
  --ion-color-primary-rgb: 98, 21, 41;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #561224;
  --ion-color-primary-tint: #722c3e;
}
.secondary-primary {
  --ion-color-secondary: var(--ion-color-primary);
  --ion-color-secondary-rgb: var(--ion-color-primary-rgb);
  --ion-color-secondary-contrast: var(--ion-color-primary-contrast);
  --ion-color-secondary-contrast-rgb: var(--ion-color-primary-contrast-rgb);
  --ion-color-secondary-shade: var(--ion-color-primary-shade);
  --ion-color-secondary-tint: var(--ion-color-primary-tint);
  --header-logo-height: 60px;
  --header-logo-width: 120px;
  --header-logo-height-minimized: 40px;
  --header-logo-width-minimized: 100px;
}
.primary-theme {
  --ion-color-primary: #51688f;
  --ion-color-primary-rgb: 81, 104, 143;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #475c7e;
  --ion-color-primary-tint: #62779a;
}
