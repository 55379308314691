.modal{
  &Header{
    position: relative;
    border-radius: 0 0 12px 12px;
    --padding-start: 5px;
    // ios 11
    --padding-top: constant(safe-area-inset-top);
    // ios >12
    --padding-top: env(safe-area-inset-top);

    &Icon{
      position: absolute;
      cursor: pointer;
      height: 24px;
      @media (max-width: 425px) {
        height: 31px;
        left: -4px;
        & > ion-icon{
          font-size: 32px;
        }
      }
      @media (min-width: 425.97px) {
        left: 6px;
      }
    }
    &Label{
      text-align: center;
      padding: 20px;
    }
    &Title{
      font-size: 22px;
      @media (max-width: 425px) {
        font-size: 20px;
      }
    }
  }

  &IonContent{
    --padding-top: 20px;
    --padding-start: 30px;
    --padding-end: 30px;
    flex-basis: auto;
  }
}

