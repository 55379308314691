.categoryIcon {
  height: var(--category-icon-size);
}

.categoriesList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 10px 10px;

  .productCard {
    flex: 0 1 30%;
    max-width: 150px;
  }
}

.productCard {
  margin: 5px;
  border-radius: var(--border-radius-card-value);
  box-shadow: 19px 8px 25px rgba(158, 152, 135, 0.16);
  text-align: center;

  &__image {
    padding: var(--category-icon-padding);
    display: inline-block;
    transform: var(--transform-scale-value);
    &--placeholder {
      padding: 0;
    }
  }

  .image-transform {
    padding: var(--category-icon-padding);
    display: inline-block;
    transform: var(--transform-scale-value-category);

    &--placeholder {
      padding: 0;
    }
  }

  &__subtitle {
    color: #3f3f3f;
    font-weight: 300;
    text-align: center;
    font-size: 12px;
    padding: 0 10px;
    text-transform: none;
    margin-bottom: 10px;
  }
}

.productsRow {
  padding: 0;
  margin-bottom: 30px;
}

.skeletonRow {
  margin: 0;
  padding: 0;
  color: black;
  margin-left: 20px;

  ion-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    gap: 15px;

    ion-skeleton-text,
    .skeletonCard,
    .skeletonText {
      --border-radius: 12px;
      margin: 2px;
    }

    .skeletonCard {
      width: 150px;
      height: 192px;
    }

    .skeletonText {
      width: 100px;
      height: 12px;
    }

    .skeletonCard,
    .skeletonText {
      background-color: #ddd;
      background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
      background-size: 400px;
      animation: skeleton-animate 1.6s infinite linear;
    }

    @keyframes skeleton-animate {
      0% {
        background-position: -100px;
      }
      40%,
      100% {
        background-position: 140px;
      }
    }
  }
}

.hide {
  display: none;
}
