.searchBox{
  display: flex;
  padding: 4px;
  height: 42px;
  border: 2px solid var(--ion-color-secondary);
  border-radius: var(--border-radius-value);
  margin: 10px 10px;

  @media (min-width: 425.98px) {
    max-width: 425px;
  }

  @media (min-width: 474.98px) {
    margin-right: auto;
    margin-left: auto;
  }

  @media (min-width: 768px) {
    padding: 0;
    border-width: 0;
    border-radius: 20px;
  }

  &__input{
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    font-size: 18px;

    &::placeholder {
      color: var(--ion-color-gray);
      font-size: 14px;
      text-align: center;
    }
  }

  &__wrapper{
    position: relative;
    align-items: center;
    width: 100%;
    padding-left: 15px;
  }

  &__button{
    padding: 15px;
    cursor: pointer;
    &:hover{
      cursor: pointer;
      background-color: rgba(220, 220, 220, 0.5);
    }
  }

}
.searchIcon{
  position: absolute;
  top: 50%;
  right: 0;

  &--md{
    @media (min-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      right: 0;
      background-color: #333e48;
      color: #fff;
      text-align: center;
      border-radius: 0 20px 20px 0;
      padding: 9px 20px;
    }
  }

  &--sm{
    @media (max-width: 767.98px) {
      width: 22px;
      height: 18px;
      top: 5px;
      right: 5px;
    }
  }
  &__icon{
    &--sm{
      @media (max-width: 767.98px) {
        width: 18px;
        height: 18px;
        color: rgba(44,44,44,0.2);
      }
    }
    &--md{
      @media (min-width: 768px) {
      }
    }
  }
}