.switcher {
  &__wrapper {
    width: 100%;
    height: 119px;
    margin-top: 30px;
  }

  &__title {
    width: 40%;
    height: 15px;
    margin: 0 0 10px;
  }

  &__button {
    max-width: 33.3%;
    height: 86px;
    margin: 0;

    &:first-of-type {
      margin-right: 5px;
    }

    @media (max-width: 375px) {
      max-width: 40%;
    }
  }
}

.buttons-wrapper {
  width: 100%;
  display: flex;
}