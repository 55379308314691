.order-details {
  color: black;
  background: white;
  border-radius: 10px;

  &-header {
    text-transform: none;
    padding: 15px 30px;
    color: var(--ion-color-secondary-contrast);
  }
  &-title {
    text-transform: none;
    // padding: 0 12px;
    background: none;
    font-size: 16px;
    font-weight: 500;
    color: black;
  }

  &-subtitle {
    text-transform: none;
    font-weight: 500;
    font-size: 14px;
    padding: 10px 30px 4px 30px;
    background: #e9e9e9;
    color: black;
  }
  &-description {
    text-transform: none;
    font-size: 12px !important;
    font-weight: lighter;
    background: rgba($color: white, $alpha: 0.7);
    padding: 8px 15px 20px 30px;
    color: black;
    ion-col {
      padding: 4px 0;
    }
  }

  &-content {
    font-weight: 400;
    background: transparent;
    padding: 0;
  }

  &-note{
    padding: 0;
  }
}

.products-wrapper {
  ion-card-content {
    padding: 0;
  }
}

.my-order-detail {
  ion-col {
    font-size: 12px;
  }
}

.showRateDriverModal_container {
  transition: max-height 0.3s;
  max-height: 0;

  &__expanded {
    max-height: 59px;
  }
}

.repeat {

  &Icon {
    color: var(--ion-color-primary);
    font-size: 16px;
  }
}

.rate {

  &Icon {
    color: var(--ion-color-primary);

    font-size: 16px;
  }
}