.row {
  width: 100%;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: hidden;
  flex-wrap: nowrap;
  justify-content: flex-start;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}

.catalog {
  width: 100%;

  &__slide {
    width: 170px;
    min-width: 170px;
    flex-shrink: 0;
    padding: 5px 5px 0;
    align-items: baseline;
  }

  &__title {
    color: var(--ion-color-primary-contrast);
    position: absolute;
    top: 0;
    bottom: 0;
    font-size: 16px;
    display: flex;
    align-items: center;
    padding-left: 20px;
  }

  &__img-wrapper {
    position: relative;
  }

  img {
    width: 100%;
    height: 100%;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.categories_slider {
  width: 100%;
  position: relative;
  overflow: hidden;
  z-index: 1;

  &::after {
    top: 0;
    bottom: 0;
    z-index: 1;
    content: "";
    right: -20px;
    width: 100px;
    position: absolute;
    pointer-events: none;
    opacity: 0.8;
    background: linear-gradient(to left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  }
}

.category__slide {
  width: auto;
  padding: 0 5px;
  display: flex;
  flex-shrink: 0;
}

.category {
  max-width: calc(var(--home-page-category-size) + 10px) !important;

  &__img {
    height: 100%;
    max-width: 100%;
    min-width: 100%;
    &-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: var(--home-page-category-size);
    }
  }

  &__img_transform {
    height: 100%;
    max-width: 100%;
    transform: var(--transform-scale-value);
    &-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: var(--home-page-category-size);
    }
  }

  &__title {
    margin: 0;
    display: block;
    text-overflow: ellipsis;
    text-align: center;
    overflow: hidden;
    max-height: 37px;
    font-weight: 400;
    font-size: 14px;
    height: 37px;
    line-height: normal;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    max-width: var(--home-page-category-size);
    white-space: break-spaces;
  }
}

.skeleton {
  flex-wrap: nowrap;

  &__col {
    flex-shrink: 0;
    max-width: 74px !important;
  }

  &__text {
    margin-left: 0;
  }
}
