.badges{
  position: absolute;
  top: 0;
  left: 0;
}

.badge{
  background: rgb(247,78,45);
  color: white;
  padding: 4px 8px;
  border-radius: 6px;
  margin-right: 4px;

  &SalePercent{
    &::before{
      content: '-'
    }
    &::after{
      content: '%';
    }
  }
}