@media (max-width: 768px) {
    .sliderWrapper {
      :global(.swiper-pagination) {
        display: none;
      }
    }
  }
  
  .sliderWrapper {
    :global(.swiper-pagination-bullets) {
      bottom: -30px;
    }
  }