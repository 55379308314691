.toolbar {
  --background: transparent;
  background: var(--toolbal-background-color-value);
  // padding-top: env(safe-area-inset-top, 20px);
  --min-height: 58px;
  // --padding-bottom: 10px;
  padding-inline: 8px;
  display: flex;
  align-items: flex-end;
  border-radius: 0 0 12px 12px;
  --border-width: 0 !important; // костыль для того чтобы убрать нижнюю полупиксельную линию
  .hideButton {
    display: none; // ask SM about that
  }
}

.title-img {
  //--header-logo-height: 60px;
  //--header-logo-width: 120px;
  height: 50px;
  width: 110px;

  @media (max-width: 424.98px) {
    height: var(--header-logo-height-minimized);
    width: var(--header-logo-width-minimized);
  }
}

.search-icon {
  height: 18px;
  width: 21px;
  padding-right: 12px !important;
}

.icon {
  margin-right: 5px;
  font-size: 20px;

  &-12 {
    font-size: 12px;
  }

  @media (max-width: 424.98px) {
    font-size: 16px;
  }

  &-inf {
    @media (max-width: 424.98px) {
      font-size: 16px;
    }
  }
}

ion-button.button {
  margin-inline-start: 5px;
}

ion-button.button-sm {
  margin-inline-start: 0;

  &::part(native) {
    @media (max-width: 424.98px) {
      padding: 0;
    }
  }

  .icon {
    @media (max-width: 424.98px) {
      font-size: 16px;
    }

    &-inf {
      @media (max-width: 424.98px) {
        font-size: 16px;
      }
    }
  }
}

ion-button.logo-button {
  height: auto;
  width: auto;

  &::part(native) {
    padding-inline-start: 0;
    padding-inline-end: 0;
  }
}

.testclass {
  gap: 4px;
}

.buttons-centered {
  justify-content: center;
}

.logo-centered {
  margin: 0 auto;
  position: relative;
  right: -4px;

  &::part(native) {
    padding-inline-start: 0;
    padding-inline-end: 0;
  }
}
