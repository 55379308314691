
.grid-wish-list {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    height: 60vh;
  }

  ion-row {
    ion-col {
      text-align: center;

      ion-icon {
        zoom: 3.9;
        color: lightgrey;
      }

      ion-button {
        height: 30px;
      }

      h4 {
        font-size: 16px;
        font-weight: bold;
        margin-top: 2px;
      }

      h5 {
        font-size: 14px;
        color: gray;
        margin-top: 0;
      }
    }
  }
}

.no-margin-icon {
  margin: 0;
}