.myOrders {
  &__cardContent {
    cursor: pointer;
  }

  &__emptyContent {
    height: 100%;
    min-height: auto;
    @media (min-width: 767.98px) {
      height: 70vh;
    }
  }

  &Product__label {
    font-size: 12px;
  }
}

.pending {
  background-color: var(--ion-color-primary);
}

.cancel {
  background-color: var(--ion-color-danger);
}

.complete {
  background-color: var(--ion-color-medium);
}

.inProcess {
  background-color: var(--ion-color-success);
}

.orderStatusBar {
  border-radius: 22px;
  padding: 5px 9px;
  color: white;
  position: relative;
  left: 9px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}
