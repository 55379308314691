.ion-toolbar {
  ion-slides {
    background-color: white;
    ion-slide {
      height: 56px;
      width: auto;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 14px;
      border-top-width: 0;
      border-left-width: 0;
      border-right-width: 0;
      border-bottom-style: solid;
      border-bottom-width: 2px;
      border-bottom-color: transparent;
      color: var(--ion-text-color);
    }
    .selected {
      border-bottom-color: black;
      background-color: var(accent-color);
    }
  }
  .disable {
    pointer-events: none;
  }
}
.ion-content {
  .grids {
    ion-row {
      margin-top: 10px !important;
    }
  }
}
.ion-footer {
  ion-toolbar {
    ion-item {
      .item-native {
        padding-left: 10px;
      }
      margin: 0;
      ion-label {
        margin-top: 7px;
        p {
          font-size: 11px;
        }
        h3 {
          text-transform: uppercase;
          color: var(--ion-color-secondary);
        }
        ion-icon {
          vertical-align: top;
        }
      }
    }
  }
}


.ion-menu {
  ion-range {
    padding-top: 22px;
    padding-left: 0;
    padding-right: 0;
  }
  ion-card {
    ion-card-header {
      padding-bottom: 0;
      text-transform: capitalize;
    }
    ion-card-title {
      color: var(--ion-text-color);
    }
  }
}
