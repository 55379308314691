// all screen styles
.product {
  min-height: 60px;
  --line-height: 0.2;

  .ion-card-header {
    padding: 12px;
  }

  .cardIrskom {
    padding: 8px;
    width: 190px;
    &Header {
      padding: 8px;
      height: 40px;
      &Title {
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        width: 100%;
        max-width: 100%;
        line-height: 1.2em;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        white-space: pre-wrap;
      }
    }
    &Content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 0;
      margin-top: 30px;

      &Price {
        font-size: 16px;
        font-weight: bold;
        color: #222;
      }
      &Quantity {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .cardContent {
    height: 42px;
    min-height: 42px;
    padding: 8px;
  }
  .title_product {
    margin-bottom: 4px;
    font-size: 14px;
    line-height: 1.5;
    overflow: hidden;
    margin: 4px;
    text-overflow: ellipsis;
    height: 40px;
  }
  .icon {
    color: white;
    background-color: var(--ion-color-secondary);
    border-radius: 100%;
    padding: 4px;
  }

  .iconBackground {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cardNoShadow {
    box-shadow: -2px 20px 25px rgba(158, 152, 135, 0.1);
    border: var(--ion-card-border-value);
    cursor: pointer;



    @media (min-width: 768px) {
      box-shadow: none;
    }
  }
  .product-card-counter {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
  }
  .product-card-background {
    width: 100%;
    border-radius: 25px;
    display: flex;
    justify-content: space-around;
    align-self: center;
    background-color: var(--ion-color-secondary);
  }

  .basketButton {
    background-color: var(--ion-color-secondary);
    border-radius: 50px;
    padding: 4px 27px;
    color: white;
    width: 100%;
    white-space: nowrap;
  }

  .basketButtonList {
    background-color: var(--ion-color-secondary);
    border-radius: 50px;
    padding: 4px 0px;
    color: white;
    width: 100%;
  }

  .counter-button {
    color: white;
    background-color: var(--ion-color-secondary);
    border-radius: 50%;
  }

  .quantityCounter {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    background-color: var(--ion-color-secondary);
    text-align: center;
    width: 100%;

    .addQuantity {
      font-size: 20px;
      vertical-align: middle;
      padding: 0;
    }
    .quantityValue {
      background: white;
      border-radius: 12px;
      margin: 2px auto;
    }

    .removeQuantity {
      font-size: 20px;
      vertical-align: middle;
      padding: 0;
    }
  }
  .quantityCounterIrskom {
    align-self: center;
    margin-top: 4px;
    margin-bottom: 4px;
    padding: 0px 12px;
    border-radius: 12px;
    background-color: white;
    text-align: center;
    width: 100%;

    .addQuantity {
      font-size: 20px;
      vertical-align: middle;
      padding: 0;
    }

    .quantityValue {
      background: white;
      border-radius: 12px;
      margin: 2px auto;
    }

    .removeQuantity {
      font-size: 20px;
      vertical-align: middle;
      padding: 0;
    }
  }
  .quantityCounterIrskom {
    align-self: center;
    margin-top: 4px;
    margin-bottom: 4px;
    padding: 0px 12px;
    border-radius: 12px;
    background-color: white;
    text-align: center;

    .addQuantity {
      font-size: 20px;
      vertical-align: middle;
      padding: 0;
    }

    .quantityValue {
      background: white;
      border-radius: 12px;
      margin: 2px auto;
    }

    .removeQuantity {
      font-size: 20px;
      vertical-align: middle;
      padding: 0;
    }
  }

  .price {
    background-color: white;
    font-size: 16px;
    font-weight: bold;
    color: #222;

    > span {
      display: flex;
      height: 30px;
      min-height: 30px;
      align-items: center;

      ins {
        display: block;
        overflow: unset;
        padding-right: 1px;

        bdi {
          line-height: 1;
          font-size: 16px;
        }

        span {
          margin: 0;
        }
      }

      del {
        display: block;
        position: absolute;
        top: -8px;

        bdi {
          line-height: 1;
          font-size: 12px;
        }
      }
    }

    &__general {
      > span {
        del {
          top: -15px;
        }
      }
    }

    &__orderPage {
      > span {
        ins {
          padding-top: 25px;
        }
      }
    }
  }

  .new-product-label {
    background: var(--ion-color-new-product);
    color: #fff;
    padding: 3px 12px;
    margin-top: 2px;
    margin-left: 2px;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 1;
    border-radius: 12px;
  }

  &Heart {
    &Icons {
      position: relative;
      z-index: 100;
    }

    &Icon {
      @media (min-width: 768px) {
        font-size: 28px;
      }
    }
  }
}

.product {
  &Basket {
    align-items: center;

    &Wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    &Icon {
      font-size: 22px;
      color: white;
      background-color: var(--ion-color-secondary);
      border-radius: 100%;
      padding: 4px;
      @media (min-width: 768px) {
        font-size: 28px;
      }
    }
  }
}

// iphone 5s styles
@media screen and (max-width: 320px) {
  .product {
    .price {
      > span {
        ins {
          bdi {
            font-size: 16px;
          }
        }

        del {
          bdi {
            font-size: 10px;
          }
        }
      }
    }
  }
}

.counter__removeProduct,
.quantityValue,
.addItem__col,
.removeItem__col {
  cursor: pointer;
}

.new-list-view {
  padding: 20px 10px;
  box-shadow: var(--border-shadow-new-list-product);
  border-bottom: var(--border-product-new-list-bottom);
  border: var(--border-product-new-list);
  border-radius: var(--border-radius-new-list-value);
  width: 100%;
  cursor: pointer;
  @media (min-width: 576px) {
    border: 1px solid transparent;
    border-radius: 12px;

    &:hover {
      border-color: var(--ion-color-primary);
    }
  }

  .product-image {
    text-align: center;

    img {
      @media (max-width: 575.98px) {
        max-height: 120px;
      }
      padding: 4px;
      border-radius: 12px;
    }
  }

  .icon {
    font-size: 22px;
  }

  .price {
    margin: 2px;
  }

  .test {
    height: 30px;

    > ion-row {
      height: 100%;
    }
  }

  .quantityCounter {
    .addQuantity {
      font-size: 24px;
    }

    .quantityValue {
      font-size: 20px;
      border-radius: 12px;
      padding: 0;
    }

    .removeQuantity {
      font-size: 24px;
    }
  }

  .stars-outer {
    display: inline-block;
    position: relative;
    font-size: 14px;

    @media (min-width: 768px) {
      font-size: 24px;
    }

    &::before {
      letter-spacing: 2px;
      content: "\2606\2606\2606\2606\2606";
      color: #ccc;
    }

    .stars-inner {
      font-size: 14px;
      position: absolute;
      top: 0;
      left: 0;
      white-space: nowrap;
      overflow: hidden;

      @media (min-width: 768px) {
        font-size: 24px;
      }

      &::before {
        letter-spacing: 2px;
        content: "\2605\2605\2605\2605\2605";
        color: var(--ion-color-primary);
      }
    }
  }

  .badges {
    position: absolute;

    .badge {
      display: inline-block;
      padding: 3px 12px;
    }

    .sale {
      color: white;
      margin-right: 4px;

      &::before {
        content: "-";
      }

      &::after {
        content: "%";
      }
    }

    .featured {
      font-size: 10px;
    }
  }
}

.productsSpecial {
  @media (min-width: 576px) {
    display: flex;
    flex: auto;
    height: 100%;
  }

  &__wrapper {
    @media (min-width: 576px) {
      flex-direction: column;
      min-height: 100%;
    }
  }

  &__imageWrapper {
    position: relative;
  }

  &__description {
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    &Wrapper {
      @media (min-width: 576px) {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        flex-grow: 1 !important;
      }
    }
  }

  &Left {
    &__heart {
      position: absolute;
      right: 0;
      top: 10px;
    }
  }
}

.descriptionWrapper {
  &__row {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    margin-bottom: 15px;
  }
}

.productBasket {
  &__wrapper {
  }

  &__price {
    > span {
      height: auto !important;
      min-height: auto !important;

      del {
        // price for sale product without sale
        top: -13px !important;
      }
    }

    &Wrapper {
      display: flex;
      align-items: center;
    }
  }
}

.wishProduct {
  position: relative;

  @media (min-width: 767.98px) {
    margin: 16px;
  }

  &__cardContent {
    @media (min-width: 575.98px) {
      padding: 0 12px 0 12px;
    }
  }

  &__grid {
    height: auto !important;
    @media (min-width: 575.98px) {
      padding: 0 !important;
      display: flex;
      align-items: center;
    }
  }

  &__priceWrapper {
    @media (min-width: 575.98px) {
      flex: auto;
      display: flex;
      align-items: center;
    }
  }

  &__counter {
    flex: auto;
  }

  &__removeButton {
    color: white;
    background-color: var(--ion-color-secondary);
    opacity: 0.5;
    cursor: pointer;
    border-radius: 100%;
    position: absolute;
    top: -10px;
    right: 5px;

    @media (min-width: 575.98px) {
      top: -15px;
    }

    height: 25px;

    &:hover {
      opacity: 1;
    }
  }
}

.productCommon {
  position: relative;

  &__heart {
    right: 5px;
    top: 5px;
  }
}

.disabled {
  cursor: default;
  opacity: 0.5;

  & > * {
    cursor: default;
  }
}

.listProduct {
  &Wrapper {
    display: flex;
  }

  &Content {
    cursor: pointer;
    .buttons {
      align-items: center;
      flex-wrap: nowrap;
      span {
        margin: 0;
        padding: 0 10px;
      }
    }

    &Img {
      width: 100%;
      max-width: 100%;
      > img {
        width: 100%;
      }
    }

    &Desc {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  &Card {
    &Price {
      background-color: white;

      > span {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
        justify-content: flex-end;

        ins {
          // price with sale
          display: block;
          overflow: unset;
          padding-right: 1px;
          color: var(--ion-color-danger);

          bdi {
            line-height: 1;
            font-size: 16px;
          }

          span {
            margin: 0;
          }
        }

        del {
          // price for sale product without sale
          display: block;
          // position: absolute;
          top: 0;

          bdi {
            line-height: 1;
            font-size: 12px;
          }
        }
      }
    }
  }
}

.descMaxHeight {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap; /* let the text wrap preserving spaces */
}

.bottomMargin {
  margin-bottom: 15px;
}

.out-of-stock{
  color: var(--ion-color-danger);
}