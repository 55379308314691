.skeletonItem {
  cursor: pointer;
  width: 100%;
  margin-left: 0;

  &_image {
    margin-left: 0;
  }
  &_header {
    display: flex;
  }
  &_price {
    flex-shrink: 1;
    margin-right: 0;
    margin-left: 0;
  }
  &_cartButton {
    margin-right: 0;
    flex-shrink: 2;
  }
}
