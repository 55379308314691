.segment {
  --background: transparent;
  height: 110px;
  width: 100%;

  &__button {
    max-width: 34.3%;
    --indicator-color: var(--ion-color-primary);
    --indicator-color-checked: var(--ion-color-primary);

    @media (max-width: 375px) {
      max-width: 40%;
    }

    &::part(native) {
      margin-bottom: 0;
      min-width: 100%;
    }

    &-icon {
      margin-bottom: 8px;
      width: 100%;
      max-width: 32px;
    }

    &-text {
      font-weight: bold;
      white-space: break-spaces;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    &-active {
      color: var(--ion-color-secondary-contrast);
    }
  }
}

.wrapper {
  display: flex;
  border-radius: 12px;
}
