/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap");

:root,
:root[mode="md"],
:root[mode="md"] {
  --ion-font-family: "BrutalType", "Roboto", "noto-sans";
  font-family: var(--ion-font-family) !important;
}

.ion-page {
  padding-bottom: var(--height-bottom-tabs, 0px);
}

ion-header {
  ion-toolbar {
    height: calc(70px + env(safe-area-inset-top, 0));
    --color: var(--ion-color-primary-contrast);

    ion-title {
      // padding-right: 0;
      ion-img {
        height: 44px;
      }
    }

    ion-buttons {
      ion-button {
        overflow: visible !important;

        ion-badge {
          --background: var(--ion-color-secondary);
          --color: var(--ion-color-secondary-contrast);
          position: absolute;
          top: 0;
          right: -10px;
        }
      }
    }

    display: flex;
    align-items: flex-end;
    border-radius: 0 0 12px 12px;
    --border-width: 0 !important;

    .hideButton {
      display: none;
    }
  }
}

ion-content {
  --offset-top: 10px !important;
  --padding-top-offset: 11px;
  --padding-top: 11px;
}

.font-weights {
  font-weight: bold;
}

.iconSize {
  zoom: 1.3;
}

.large {
  font-size: 16px;
}

.medium {
  font-size: 14px;
}

.small {
  font-size: 12px;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.top-icon-header {
  ion-col {
    padding-bottom: 0;

    ion-icon {
      font-size: 12px;
      margin-right: 3px;
      margin-left: 3px;
    }

    h5 {
      text-transform: capitalize;
      font-size: 12px;
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 5px;
    }
  }
}

.top-icon-header {
  color: var(--ion-color-primary-contrast);

  #second {
    height: 10px;
    font-size: 10px;
    color: var(--ion-color-secondary);
    text-transform: uppercase;

    ion-icon {
      font-size: 10px;
      margin: 0;
    }
  }
}

// .swiper-slide {
//   text-align: left;
// }
//=============================== rtl layout =========================
[dir="rtl"] {
  ion-header {
    ion-toolbar {
      ion-buttons {
        ion-button {
          ion-badge {
            right: auto;
            left: 1px;
          }
        }
      }
    }
  }

  .top-icon-header {
    #second {
      margin-right: auto;
      margin-left: 0;
    }
  }
}

[dir="rtl"] {
  .banner-slider.swiper-container,
  .product-slides.swiper-container {
    padding-right: 0;
  }

  .swiper-slide {
    text-align: right !important;
  }

  .product-component {
    ion-item {
      #list-heart-icon {
        right: auto;
        left: 10px;
      }

      .sale {
        right: auto;
        left: 0;
      }

      .featured {
        right: auto;
        left: 0;
      }

      ion-label {
        margin-right: 10px;
      }

      ion-thumbnail {
        .badge-img {
          right: 9px;
          left: auto;
          top: 9px;
          transform: rotate(90deg) scaleX(1);
        }
      }
    }
  }
}

app-product {
  width: 100%;
}

.animate-item {
  animation: fadeIn;
}

.rotation-animation {
  animation-name: rotate;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// ion-content>*{
// margin-top: 30px;
// } якобы решает проблему с белым пятном на тайтле

ion-footer {
  & > div.footer-background {
    backdrop-filter: none;
  }
}

.numberInputWrapper {
  padding: 8px 8px 8px 0;

  input {
    border: none;

    &:hover:active:focus {
      border: none;
    }

    &:focus-visible {
      outline: 0;
    }
  }
}

.oneButtonFooter {
  --ion-safe-area-bottom: 0;

  &__toolbar {
    --border-style: none;
    box-shadow: 0 0 30px 0 rgba(169, 165, 153, 0.15);
  }

  &__button {
    --border-raduis: 12px;
    margin: 5px 10px;
  }
}

.outOfStockAlert {
  &_message,
  &_productsList {
    text-align: left;
  }

  &_productsList {
    padding-left: 25px;
    max-height: 150px;
    overflow-y: scroll;
  }
}

@media (min-width: 768px) {
  ion-app {
    .ion-page {
      overflow-y: auto;
    }
  }
  ion-content {
    &::part(scroll) {
      padding-bottom: 0;
    }
  }
}

.addReviewModal {
  .ion-page {
    padding-bottom: 0;
  }
}

// FOR RATE DRIVER
@media (min-width: 768px) {
  // MODAL WHEN USER ADD PRODUCT REVIEW
  .addReviewModal {
    .modal-wrapper {
      --max-height: 505px;
    }
  }
}

// COMMON STYLES FOR MODAL IN PROJECT
.commonModal {
  &SupTitle {
    display: block;
    color: grey;
    margin-bottom: 8px;
    font-size: 14px;
    text-transform: capitalize;
    position: relative;
  }
}

.rateDriverModal {
  .ion-page {
    padding-bottom: 0;
  }
}

// FOR RATE DRIVER
@media (min-width: 768px) {
  .rateDriverModal {
    .modal-wrapper {
      --max-height: 480px;
    }
  }

  ion-app {
    .ion-page {
      overflow-y: auto;
    }
  }
  ion-content {
    &::part(scroll) {
      padding-bottom: 0;
    }
  }
}

.addReviewModal {
  .ion-page {
    padding-bottom: 0;
  }
}

// FOR RATE DRIVER
@media (min-width: 768px) {
  // MODAL WHEN USER ADD PRODUCT REVIEW
  .addReviewModal {
    .modal-wrapper {
      --max-height: 505px;
    }
  }
}

// COMMON STYLES FOR MODAL IN PROJECT
.commonModal {
  &SupTitle {
    display: block;
    color: grey;
    margin-bottom: 8px;
    font-size: 14px;
    text-transform: capitalize;
    position: relative;
  }
}

.global-content {
  min-height: 80vh;
}

// .flex {
// display: flex;
// }

.shippingAddress_deliverySelect {
  button {
    line-height: 1;
  }

  & .shippingAddress_deliverySelectOption {
    & ion-radio {
      &::part(container) {
        border-color: transparent;
      }
    }
  }
}

.popover-viewport.sc-ion-popover-md {
  overflow-y: scroll;
  max-height: 100%;
  display: contents;
}

.sc-ion-select-popover-h {
  border: none;
}

.sc-ion-select-popover-h {
  width: 100%;
}

.shippingAddress_deliverySelectOption {
  &::part(native) {
    position: relative;

    &.item-inner {
      transition: opacity 15ms linear, background-color 15ms linear;

      &:hover {
        background: var(--background-hover);
        opacity: var(--background-hover-opacity);
      }
    }

    &::before {
      position: absolute;
      content: "+";
      opacity: 1;
      inset: auto;
      font-size: 32px;
      right: 17px;
      //background: transparent;
      color: var(--ion-color-primary);
    }
  }
}

.action-sheet-button.shippingAddress_deliverySelectOption > .action-sheet-button-inner {
  position: relative;

  &::after {
    content: "+";
    opacity: 1;
    font-size: 32px;
    margin-left: 10px;
    color: var(--ion-color-primary);
  }
}

.addressForm_actionSheet {
  --button-color: var(--ion-color-dark);
}

ion-modal {
  .ion-page {
    padding-bottom: 0;
  }
}

// костыль чтобы свайпер норм работал после обновы
.swiper {
  touch-action: pan-y;
}

.products-slider__pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  margin-top: 15px;

  .swiper-pagination-bullet {
    background-color: grey;
    margin: 0 4px;

    &-active {
      background-color: var(--ion-color-primary);
      border-radius: 3px;
      width: 30px;
    }
  }
}
