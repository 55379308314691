.imageSlider {
  margin-top: 10px;

  ion-img {
    object-fit: cover;
  }

  ion-slides {
    height: 100%;
    width: 90vw;
    overflow: visible;

    div:first-child {
      // slide swiper wrapper
      ion-slide {
        box-sizing: content-box;

        img {
          background: white;
          display: block;
          margin-right: 4px;
          overflow: hidden;
          border-radius: 12px;
          // box-shadow: 20px 0 64px rgba(0, 0, 0, 0.4);
          box-shadow: 20px 0 64px rgba(158, 152, 135, 0.35);
        }
      }
    }
  }
}

.product-tags-top {
  position: absolute;
  top: 0;
  left: 0;

  .tag {
    background: rgb(247, 78, 45);
    color: white;
    padding: 4px 8px;
    border-radius: 6px;
    margin-right: 4px;
  }

  .salePercent {
    &::before {
      content: "-";
    }

    &::after {
      content: "%";
    }
  }
}

.stockStatus {
  margin-left: 5px;
  margin-top: 5px;
}

.averageRatingWrapper {
  display: flex;
  justify-content: flex-end;
  align-self: center;

  .averageRating {
    font-size: 14px;
    background-color: var(--ion-color-primary);
    border-radius: 6px;
    padding: 2px 6px 2px 2px;
    margin-right: 10px;

    ion-icon {
      padding: 0 4px;
      font-size: 12px;
    }
  }
}

.contentBox {
  .productName {
    background: white;
    z-index: 1;
    padding: 10px 20px;
    margin-left: 5px;
    font-size: 20px !important;
  }
}

.productDescription {
  &Title {
    background: yellow;
    margin: 20px 15px;
  }
}

.productInfoSegment {
  width: 100%;
  background: white;

  .productInfoSegmentButtons {
    height: 40px;
    margin: 10px 0;
    padding: 0 10px;
    background: rgb(236, 236, 236);
    border-radius: 0;

    ion-segment-button {
      --border-radius: 8px;
      // --background-activated: var(--ion-color-primary);
      // --background-checked: var(--ion-color-primary);
      // --background-hover: var(--ion-color-primary);
      --color-checked: var(--ion-color-primary-contrast);
      --indicator-color: var(--ion-color-primary);
    }
  }

  ion-grid {
    margin: 20px 10px;
  }
}

.productDetailWrapper {
  padding-bottom: 60px;
}

.buttonBackdrop {
  z-index: 2000;
  background: rgba(255, 255, 255, 0.8);
  bottom: 80px;
  height: 80px;
  padding: 10px;
  left: 0;
  right: 0;

  .footerButtonSection {
    display: flex;
    background: var(--ion-color-primary);
    height: 60px;
    border-radius: 12px;
    padding: 5px;
    justify-content: space-between;

    .buttonProductInfoWrapper {
      display: flex;

      .buttonProductImageWrapper {
        height: 55px;
        overflow: hidden;

        img {
          height: 50px;
          border-radius: 16px;
        }
      }

      .buttonProductPriceWrapper {
        display: flex;
        align-items: center;

        .buttonProductPrice {
          margin: 4px 8px;

          .dangerousHtmlMarkup {
            margin: 0 2px;
            display: block;

            > span {
              display: flex;
              flex-direction: column-reverse;

              ins {
                text-decoration: none;
                font-size: 20px;
              }

              del {
                font-size: 12px;
              }

              > span {
                font-size: 24px;
              }
            }
          }
        }
      }
    }

    .buttonAddToCartIconWrapper {
      padding: 10px;
      font-size: 30px;
      border-left: 1px solid white;
    }

    .productQuantityChangeWrapper {
      background: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 120px;
      font-size: 22px;
      border-radius: 12px;

      ion-icon {
        padding: 8px;
      }
    }
  }

  .test {
    padding: 10px;
    width: 100%;
    height: 100%;
  }
}

.firstRow {
  margin: 0 -20px;

  ion-col {
    z-index: 2;
  }
}

.relatedItems {
  color: var(--ion-primary-contrast);
  margin: auto 20px;
  font-size: 20px;
  font-weight: bold;
}

.iconReplace {
  width: 42px;
}

.otherProducts {
  padding: 10px 0;

  &__slides {
    overflow: visible;
  }
}

.sameCategorySliderWrapper {
  @media (min-width: 768px) {
    &::after {
      content: "";
      position: absolute;
      background: white;
      top: 0;
      right: -20px;
      bottom: 0;
      width: 100px;
      background: linear-gradient(to left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
      z-index: 1;
      pointer-events: none;
    }
  }
}

.sameCategorySliderWrapper,
.productDetailSliderWrapper {
  ion-slides {
    overflow: hidden;
  }
}

.productDetailSlider {
  height: 480px;

  &__video {
    height: 100%;
  }
}

.slider-item {
  max-width: 150px;
}

.productDetailInfo {
  padding-block-end: 1rem;
  padding-inline: 0.75rem;
}

.productDetailHeadInfo {
  padding-block: 1rem;
  padding-inline: 0.75rem;
}