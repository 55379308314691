.ion-slide-max-width {
  width: 190px !important;
  max-width: 190px !important;
  margin-right: 10px;
}

.title {
  margin: 0 0 10px;
}

.slider {
  overflow: visible;
  margin-bottom: 20px;
  @media (min-width: 765.98px) {
    margin-bottom: 61px;
  }
}

.wrapper {
  padding-left: 10px;
}

@media (max-width: 768px) {
  .sliderWrapper {
    :global(.swiper-pagination) {
      display: none;
    }
  }
}

.sliderWrapper {
  :global(.swiper-pagination-bullets) {
    bottom: -30px;
  }
}
