.rating{

  &Label{
    font-size: 14px;
    background-color: var(--ion-color-primary);
    border-radius: 6px;
    padding: 2px 6px 2px 2px;
    margin-right: 10px;
  }

  &Icon{
    padding: 0 4px;
    font-size: 12px;
  }
}