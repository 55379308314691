.form {
  padding-top: 20px;
}

.modal__button {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 10px;
}

.dropdown {
  max-height: 0;
  transition: max-height 1s;
  overflow: hidden;

  &__active {
    max-height: 500px;
    transition: max-height 1s;
  }
}

.checkboxItem {
  margin-bottom: 23px;
}
