.ratingUsers {
  .item {
    background-color: transparent;
    align-items: flex-start;

    ion-avatar {
      margin-top: 10px;
    }

    h2 {
      font-weight: bold;
    }

    p {
      p {
        margin-top: 2px;
        white-space: normal;
      }
    }
  }
}

.ratingProduct {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;

  li {
    display: flex;
    align-items: center;

    h6 {
      margin-top: 5px;
      margin-left: 10px;
    }
  }

  h6 {
    color: cornflowerblue;
    margin-left: 15px;
  }
}

.stars {
  &Outer {
    display: inline-block;
    position: relative;
    font-size: 25px;

    &::before {
      content: "\2606\2606\2606\2606\2606";
      color: #ccc;
    }
  }

  &Inner {
    font-size: 25px;
    position: absolute;
    top: 0;
    left: 0;
    white-space: nowrap;
    overflow: hidden;

    &::before {
      content: "\2605\2605\2605\2605\2605";
      color: var(--ion-color-primary);
    }
  }
}