.payment {
    ion-card-header{
        text-transform: none;
        padding: 15px 30px;
        color: black;
    }
    ion-card-content{
        padding: 0;
        ion-select{
            width: 100%;
        }
    }
}

.payment-select {
    padding: 0 0 0 10px;
}



.order {
    ion-col {
        font-size: 12px;
    }
    ion-card {
        ion-card-header {
            // background-color: #eee;
        }
    }

    &HeaderMobile{
        margin-bottom: 0;
    }

    &Wrapper{
        padding-left: 15px;
        padding-right: 15px;
    }

    &Toolbar{
        box-shadow: none;
        --background: transparent;
        --border-width: 0px;
        --padding-top: 0px;
        --padding-end: 0px;
        --padding-start: 0px;
        --padding-bottom: 0px;

        &Wrapper{
            --background: transparent;
        }

        &Button{
            margin: 0;
        }
    }
}