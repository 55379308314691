.wrapper {
  padding: 10px;
  display: flex;
  height: 85px;
  margin: 24px 16px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.12) 0 4px 16px;
  background: white;
  border-radius: 8px;

  @media (min-width: 424.98px) {
    height: 100px;
  }
  @media (min-width: 575.98px) {
    height: 120px;
  }
  @media (min-width: 1023.98px) {
    height: 107px;
  }


  &-img {
    margin-right: 15px;
  }

  &-descr {
    flex-grow: 1;
    margin-bottom: 8px;
  }

  &-counter {
    display: flex;
    justify-content: flex-end;
  }

  &-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.img {
  height: 100%;
  width: 90px;
}

.descr {
  margin-bottom: 5px;

  &-second {
    width: 80%;

    @media (max-width: 424.98px) {
      display: none;
    }
  }
}

.counter {
  width: 90px;
  height: 35px;

  @media (max-width: 575.98px) {
    height: 25px;
  }
}