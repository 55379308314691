.phoneInput {
  width: 100%;

  :global(.special-label) {
      display: none;
  }

  &_input {
      width: 100%;
      padding: 12px 17px;
      border: none;
      background: transparent;

      &:focus {
          outline: none;
      }
  }
}

.disabled {
  opacity: 0.5;
}