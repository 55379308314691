* {
  --height-bottom-tabs: calc(70px + env(safe-area-inset-bottom, 0));
}

.tabs {
  bottom: 0;
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: var(--height-bottom-tabs);
  background-color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: env(safe-area-inset-bottom, 0);
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.iconContainer {
  position: relative;
  display: flex;
  justify-content: center;
}

.icon {
  color: gray;
  font-size: 1.5rem;
}
.iconActive{
  color: var(--ion-color-primary);
  font-size: 1.5rem;
}

.notification {
  padding: 2px;
  border-radius: 20px;
  min-width: 15px;
  height: 15px;
  background-color: var(--ion-color-secondary);
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(30%, -10%);
  color: var(--ion-color-secondary-contrast);
  font-size: 13px;
  line-height: 13px;
  font-family: "BrutalType-Medium";
  display: flex;
  flex-direction: column;
  align-items: center;
  &Counter{
    background: transparent;
  }
}

.label {
  color: gray;
  font-size: 13px;
  font-family: "BrutalType-Medium";
  white-space: nowrap;
  margin-top: 3px;
}

.activeLabel {
  @extend .label;
  color: black;
  font-weight: 500;
}
