.password{
  &Input {
    max-width: 100%;
  }

  &Wrapper{
    width: 100%;
    position: relative;
  }
  
  &ShowIcon{
    cursor: pointer;
    position: absolute;
    z-index: 100;
    top: 10px;
    right: 25px;
    font-size: 24px;
    color: #373737;
  }
}