ion-item {
  --ion-background-color: transparent !important;
}

ion-label {
  margin: 0 !important;
}

.margin-bottom {
  margin-bottom: 20px;
}

.footer {
  font-size: 14px;
  color: #fff;
  background: #323436;

  &Container {
    background: #323436;
  }

  &Top {
    padding-top: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid var(--ion-color-gray);
  }

  &List {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: transparent;

    ion-item {
      --min-height: 0px;
    }

    ion-label {
      margin-bottom: 10px !important;
    }

    &Header {
      font-weight: 600;
      color: #fff;
      margin-bottom: 20px;
    }
  }


  &Link {
    color: #fff;
    margin-bottom: 5px;
    cursor: pointer;
  }

  &Contacts {
    &Phone {
      display: inline-block;
      font-size: 28px;
      font-weight: 600;
      color: var(--ion-color-secondary);
      margin-bottom: 10px;
    }

    &Title {
      color: #fff;
      font-size: 16px;
      margin-top: 0;
      margin-bottom: 20px;
    }
  }

  &Media {
    &Item {
    }
  }

  &Bottom {
    padding: 0 20px 10px 20px;
    font-size: 13px;
  }
}