.checkboxItem {
  --min-height: 0;
  --padding-start: 8px;
  --padding-top: 4px;
  --padding-bottom: 4px;
  --background-hover: none;
  --background-activated: none;
  --background-focused: none;

  &_checkbox {
    width: 21px;
    height: 21px;
    margin: 0 5px;
  }

  &_label {
    margin: 0;
    font-size: 14px;
    color: #333e48;
  }
}