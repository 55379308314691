.title {
  position: relative;
  z-index: 9;
  padding: 20px;
  font-size: 24px;
  font-weight: bold;
  color: #404040;
  background-color: white;
  margin: 0;
  box-shadow: 0 0 40px rgba(95, 95, 95, 0.11);

  @media (min-width: 768px) {
    box-shadow: none;
  }
}