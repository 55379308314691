.grade {

  &Block {
    height: 11px;
    border-radius: 12px;
    display: inline-block;
    margin-left: 7px;
    position: relative;
    z-index: 2;
  }

  h4 {
    margin-top: 0;
    margin-bottom: 0;
  }

  h1 {
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;

    ion-icon {
      vertical-align: text-top;
    }
  }
  h6 {
    color: var(--ion-color-secondary);
    margin-bottom: 0;
    margin-top: 0;
    text-align: center;
  }
  ul {
    padding-left: 15px;
    margin-bottom: 0;
    border-left: 1px solid #ccc;
    li {
      display: flex;
      align-items: center;
      font-size: 16px;
      list-style: none;
      position: relative;

      &::after {
        content: "";
        background-color: #f4f4f4;
        border-radius: 12px;
        height: 11px;
        width: calc(100% - 36px);
        position: absolute;
        left: 36px;
        z-index: 1;
        top: 5px;
      }
    }
  }
  .button {
    min-height: 45px;
  }
}

.animate {
  animation: fadeInRight 0.75s;
}

.mg-l5{
  margin-left: 5px;
}