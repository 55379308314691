.header {
    background: var(--ion-color-primary);
    border-radius: 0 0 12px 12px;
    height: 93px;
    ion-toolbar{
        --background: transparent;
        // height: 20px;
    }
}

.searchBar {
    // padding: 10px;
    // background: white;
    // border-radius: 18px;
    // margin: 5px 10px 15px 10px;
    // display: flex;
    // position: relative;
    height: 32px;
    padding: 4px;
    background: white;
    border-radius: 18px;
    margin: 30px 8px 9px 8px;
    display: flex;
    position: relative;

    &__input {
        width: 100%;
        outline: none;
        border: none;
        font-size: 18px;
        margin-left: 15px;
        input{
            &::placeholder {
                font-size: 14px;
                text-align: center;
            }
        }
    }

    &__button {
        // position: absolute;
        // background: transparent;
        // font-size: 26px;
        // right: 20px;
        // top: 0;
        // bottom: 0;
        position: absolute;
        background: transparent;
        right: 4px;
        top: 0;
        bottom: 0;
    }
}

.categoriesList {
    // padding: 0 30px;

    ion-list-header {
        font-size: 30px;
        font-weight: normal;
        margin: 20px 0;
        padding: 0 30px;
        color: #373737;
    }

    ion-item {
        font-size: 16px;
        color: #333;
        --inner-padding-top: 5px;
        --inner-padding-bottom: 5px;
        --border-color: #ddd;
        --padding-start: 30px;
        --padding-end: 30px;
        --detail-icon-color: var(--ion-color-primary);
        --detail-icon-opacity: 1;
    }
}

.search-icon{
    width: 22px;
    height: 18px;
    color: rgba(44,44,44,0.2);
}

.productsRow {
  padding: 0 13px;
  margin: 10px 0 30px 0;
}

.hide {
  display: none;
}