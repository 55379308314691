.addReview{
  &Header {
    position: relative;
    font-size: 30px;
    font-weight: normal;
    margin: 20px 0 10px 0;
    padding: 0 30px;
    color: #373737;
  }

  &StarsRow {
    align-items: center;
    flex-wrap: nowrap;
  }

  &StarsCol {
    display: flex;
    flex-wrap: nowrap;
  }

  //&StarsTitle {
  //  flex-grow: 0;
  //  padding-right: 10px;
  //  white-space: nowrap;
  //}

  &Star {
    zoom: 1.7;
    margin-right: 2px;

    &Wrapper{
      cursor: pointer;
    }
  }

  &DescriptionItem {
    --inner-padding-end: 0;
  }

  &Description {
    border: 1px solid var(--ion-color-primary);
    border-radius: 12px;
    box-shadow: 0 0 20px -18px var(--ion-color-primary);
    --padding-start: 10px !important;
  }

  &Button{
    --border-raduis: 12px;
    margin: 20px 0 5px 0;
  }
}
