.pageTitle {
  padding: 20px;
  font-weight: bold;
  color: #404040;
  background-color: white;
  margin: 0;
  box-shadow: 0 0 40px rgba(95, 95, 95, 0.11);

  @media (min-width: 768px) {
    box-shadow: none;
  }
}

.products{
  &__isEmpty{
    display: flex;
    flex-direction: column;
  }

  &Row {
    padding: 0;
    margin: 5px 0 70px 0;

    @media (min-width: 768px) {
      margin-bottom: 100px;
    }

    &__col{
      @media (min-width: 768px) {
        display: flex;
        flex-direction: column;
      }
    }
  }

  &Filter {
    border-bottom: 1px solid #ccc;
  }

  &NotFound{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    ion-row {
      ion-col {
        ion-icon {
          zoom: 3.9;
        }
      }
    }
  }
}

.infiniteScroll{
  &--mobile{
    margin-top: -50px;
  }

  &--desktop{
    margin-top: -80px;
  }
}

.subCategory{
  min-width: auto;
}

.infiniteScroll {
  margin-top: -50px;
}
.categoryDescription{
  &__title{
    margin-left: 20px;
  }

  &__html{
    padding: 20px;
    white-space: pre-wrap;

    > embed {
      width: 100%;
      height: 220px;
      max-width: 860px;
      @media (min-width: 424.98px) {
        height: 240px;
      }
      @media (min-width: 575.98px) {
        height: 340px;
      }
      @media (min-width: 767.98px) {
        height: 500px;
      }
    }
  }
}

.width-100 {
  width: 100% !important;
}