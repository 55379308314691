.dropdown_wrapper {
    position: relative;
    overflow: visible;
    --min-height: 0px !important;
    width: 100%;
    z-index: 3;

    .dropdown {
        width: 90%;
        position: absolute;
        z-index: 100;
        top: 102%;
        right: 0;
        left: 0;
        margin: 0 auto;
        max-height: 270px;
        overflow-y: scroll;
        box-shadow: 0 10px 17px 8px rgba(169, 165, 153, .15);
        border-radius: 0 0 10px 10px;
        --ion-background-color: white;
    
        &_item {
            --background-color: white;
            --padding-start: 10px;
            --padding-end: 10px;
            font-size: 15px;
        }

        &_label {
          max-width: max-content;
          white-space: normal;
        }

        &_spinner {
            margin: 10px auto;
            display: flex;
        }
    }

}

.dropdown{

    &Input{
        border: 1px solid #ddd;
        border-radius: 22px;
        --padding-top: 12px !important;
        --padding-bottom: 12px !important;
        --padding-start: 17px !important;
        --padding-end: 17px !important;
        transition: border-color 0.2s;
        color: #333e48;

        &:focus-within,
        &:active {
            border-color: #66afe9;
            transition: border-color 0.2s;
        }
    }

    &Label{
        font-size: 14px !important;
        color: #333e48;
        font-weight: bold;
        white-space: normal;
        display: block;
        margin-bottom: 10px !important;
        padding-left: 5px;
    }
}