ion-icon{
  font-size: 24px;
  pointer-events: none;
}

.toolbar{
  &Desktop{
    display: flex;
    top: 0;
    z-index: 100;
    margin-bottom: 30px;

    &Wrapper{
      --padding-start: 0;
      --padding-bottom: 0;
      --padding-end: 0;
      --padding-top: 0;
      border-radius: 0;
    }
  }

  &Top{
    display: flex;
    align-items: center;
    padding: 25px 15px 25px 15px;
  }
  &Bottom{
    display: flex;
    align-items: center;
    padding: 0 15px 0 15px;
    background: var(--ion-color-primary);
  }

  &Logo{
    display: inline-block;
    width: 195px;
    padding-right: 25px;
    cursor: pointer;
    margin-left: -18px;

    &Wrapper{
      max-width: 300px;
      min-width: 300px;
      height: 62px;
    }
  }
  &Sale{
    font-weight: bold;
    cursor: pointer;
  }
  &Phone{
    color: #000000;
    font-weight: bold;
    font-size: 1.5em;
  }
  &Catalog{
    display: flex;
    align-items: center;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    color: var(--ion-color-secondary-contrast);

    >span{
      display: inline-block;
      margin-right: 10px;
    }
  }
  &Search{
    padding-left: 20px;

    @media (min-width: 1040px) {
      padding-left: 80px;
    }
  }

  &Icons{
    color: var(--ion-color-secondary-contrast);
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  &Icon{
    display: block;
    margin: 0 10px;
    cursor: pointer;

    &>img{
      width: 100%;
    }

    &Basket{
      cursor: pointer;
      &Wrapper{
        display: flex;
        align-items: center;
        position: relative;
        min-width: 118px;
      }
      &Badge{
        display: flex;
        align-items: center;
        position: absolute;
        height: 18px;
        padding: 0 7px;
        border-radius: 10px;
        min-width: 20px;
        width: auto;
        background-color: #333e48;
        color: #fff;
        top: -10px;
        left: 22px;
        font-size: 12px;
      }
    }
    &Sum{
      cursor: pointer;
      font-size: 1.142em;
      font-weight: 700;
    }
    &User{}
  }
}