@media (max-width: 768px) {
  .ionSliderWrapper .swiper-pagination {
    display: none;
  }
}

.ionSliderWrapper .swiper-pagination-bullets {
  bottom: -30px;
}

.ionSliderWrapper .swiper-pagination-bullet {
  cursor: pointer;
  background-color: grey;
}
.ionSliderWrapper .swiper-pagination-bullet-active {
  width: 30px;
  background-color: var(--ion-color-primary);
  border-radius: 3px;
}
