.slide{
  &Img{
    width: 100%;
    height: 100%;
    background: white;
    display: block;
    margin-right: 4px;
    overflow: hidden;
    border-radius: 12px;
    object-fit: contain;
    &Wrapper{
      width: 100%;
      height: 100%;
    }
  }
}

