.senFeedbackModal {
  margin-left: auto;
}

.bigText {
  height: 200px;
}

.SendUserRequestForm {

	&_header {
		font-size: 30px;
		font-weight: normal;
		margin: 20px 0 10px 0;
		padding: 0 30px;
		color: #373737;
	}

	&_descriptionItem {
      --inner-padding-end: 0;
      --padding-start: 0;
      --padding-end: 0;
      --padding-top: 0;
      --padding-bottom: 0;
      margin: 0 0 20px 0;

    .SendUserRequestForm_descriptionItemLabel {
      font-size: 15px;
      margin-left: 5px;
      color: #373737;
    }

    &::after{
      content: attr(data-invalid-text);
      font-size: 14px;
      position: relative;
      display: block;
      color: var(--ion-color-danger);
      padding: 0 10px;
      transition: max-height .3s;
      max-height: 0;
    }

    &__required {
      .SendUserRequestForm_description {
        border-color: var(--ion-color-danger);
      }
      
      &::after{
        transition: max-height .3s;
        max-height: 16px;
      }
    }
	}

	&_description {
    transition: border-color .3s;
    border: 1px solid var(--ion-color-primary);
		border-radius: 12px;
		box-shadow: 0 0 20px -18px var(--ion-color-primary);
		--padding-start: 10px !important;
    color: #373737;
	}

  &_maskedInputWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    flex-grow: 1;

    input {
      font-size: 16px;
      border: none;
      outline: none;
      width: 100%;
      color: #373737;
      background: none;

      &:disabled {
        opacity: 0.4;
      }
    }
  }
}
