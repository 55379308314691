.newAddress, .create, .delete {
  cursor: pointer;
}

.address {
  text-overflow: ellipsis;
  overflow: hidden !important;
  white-space: nowrap !important;
}

.newAddress {
  font-weight: bold;
}

.newAddress ion-icon{
  --ionicon-stroke-width: 60px;
}