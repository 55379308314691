.required {
  &::after {
    content: "Заполните обязательное поле";
    border-top: 1px solid var(--ion-color-danger);
    font-size: 14px;
    position: relative;
    display: block;
    color: var(--ion-color-danger);
  }
}

.invalid-mail {
  &::after {
    content: "Укажите корректный Email адрес";
    border-top: 1px solid var(--ion-color-danger);
    font-size: 14px;
    position: relative;
    display: block;
    color: var(--ion-color-danger);
  }
}

.shipping {
  &__addressManually {
    z-index: 1990;
    height: 100px;
    margin: 0;

    ion-item {
      width: 100%;
    }
  }

  &__addressSelect {
    z-index: 10;
    width: 100%;
    overflow: hidden;

    ion-item {
      width: 100%;
    }
  }

  &Wrapper {
    padding: 15px 15px 60px 15px;
  }

  @media (min-width: 768px) {
    &Accept {
      box-shadow: none;

      &Button {
        margin: 0;
      }
    }
  }
}

.myAccountForm {
  &__item {
    --inner-padding-end: 0;
    margin-bottom: 20px;

    &--noMargin {
      margin-bottom: 0;
    }
  }

  &__label {
    font-size: 14px;
    color: #333e48;
    font-weight: bold;
    white-space: normal;
    margin-bottom: 10px;
  }

  &__input {
    border: 1px solid #ddd;
    border-radius: 22px;
    padding: 12px 17px;
    //--padding-top: 12px;
    //--padding-bottom: 12px;
    //--padding-start: 17px;
    //--padding-end: 17px;
    transition: border-color 0.2s;
    color: #333e48;

    &:focus-within,
    &:active {
      border-color: #66afe9;
      transition: border-color 0.2s;
    }
  }

  &__select {
    width: 100%;
  }

  &__selectItem {
    --border-radius: 20px;
  }

  &__title {
    font-size: 22px;
    color: #333e48;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    position: relative;

    &::after {
      content: "";
      border-bottom: 2px solid var(--ion-color-primary);
      width: 20%;
      position: absolute;
      bottom: -1px;
      display: block;
    }
  }

  &__alert {
    margin-top: 5px;
    padding-left: 5px;
    display: flex;
    font-size: 14px;
    max-width: 100%;
    white-space: normal;
  }
}

.checkboxItem::part(native) {
  padding-left: 0;
}

.dropdown {
  width: 100%;
  margin-top: 20px;
  transition: max-height 1s;

  &__active {
    width: 100%;
    transition: max-height 1s;
  }
}

.switcher {
  &__wrapper {
    margin-bottom: 10px;
  }

  &__label {
    color: var(--ion-color-dark);
    font-weight: bold;
    margin-bottom: 10px !important;
    display: block;
  }
}
