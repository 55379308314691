.loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0;
  padding-left: 1.8rem;
}

.container {
  width: 100%;
  height: 100%;
  position: relative;
}

.markerIcon {
  z-index: 1;
  opacity: 0.99;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-53%, -50%);
  margin-top: -18px;
  pointer-events: none;

  &::after {
    content: "";
    height: 3px;
    width: 5px;
    position: absolute;
    bottom: -2px;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: orange;
    border-radius: 50%;
    //box-shadow: 0 0 5px #000000FF;
  }

  img {
    transition: 0.4s all;
  }
}

.confirmAddressButton {
  box-shadow: 0 8px 24px rgba(192, 195, 200, 0.5);
  border-radius: 9px;
  color: white;
  color: var(--ion-color-dark);
  margin-inline-end: 0.5rem;

  &::part(native) {
    background-color: white;

    &:hover::after {
      background: white;
    }
  }

  &_wrapper {
    position: absolute;
    text-align: center;
    right: 0;
    bottom: 30px;
    z-index: 1;
    left: 0;
  }

  &Spinner {
    width: 24px;
  }
}

.markerIsNotDrag {
  transform: translate(0px, 0px);
}

.markerDragging {
  transform: translate(0px, -10px);
}

.locateBtn {
  position: absolute;
  z-index: 1;
  height: 40px;
  width: 40px;
  right: 10px;
  bottom: 100px;
  --border-radius: 50px;

  &Icon {
    width: 24px;
  }
}
