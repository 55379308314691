.breadcrumb{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: small;
  color: #0e0e0e7a;
  cursor: pointer;
  &__icon{
    display: inline-block;
    margin: 0 15.5px;
    font-size: 13px;
  }
}

.breadcrumbs{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 15px 15px 20px;
}