.order{

  &Summary {
    color: black;
    border-radius: 10px;
    &Header {
      text-transform: none;
      padding: 15px 30px;
      color: color(--ion-color-secondary-contrast);
    }
    &Title {
      text-transform: none;
      background: none;
      font-size: 16px;
      font-weight: 500;
      color: var(--ion-color-secondary-contrast);
    }
    &Subtitle {
      text-transform: none;
      font-weight: 500;
      font-size: 14px;
      padding: 10px 30px 10px 30px;
      background: rgba(225, 225, 225, 0.74);
      color: black;
      margin: 0;
    }
    &Description {
      text-transform: none;
      font-size: 12px !important;
      font-weight: lighter;
      background: rgba($color: white, $alpha: .7);
      padding: 8px 15px 20px 30px;
      color: black;
      ion-col{
        padding: 4px 0;
      }
    }

    &Content {
      font-weight: 400;
      background: transparent;
      padding: 0;
      margin: 0;
    }

    &Payment{
      padding-bottom: 0;
      padding-left: 10px;
      padding-right: 10px;
      &List{
        padding: 0;
      }
      &Item{
        font-size: 14px;
        --padding-start: 10px;
        --background-hover: none;
        --background-activated: none;
        --background-focused: none;

        ion-select {
          width: 100%;
        }
      }
    }

    @media (min-width: 992px) {
      &Title {
        font-size: 20px;
      }
      &Subtitle{
        font-size: 18px;
      }
      &Description, &PaymentItem{
        font-size: 16px;
      }
    }
  }

  &Spinner{
    &Wrapper{
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
    }
  }
}

.paymentMethod {

  &__button {
    min-height: 44px;
    white-space: normal;
  }

  &__icon {
    font-size: 30px;
    min-width: 30px;
  }
}

.hasOpacity {
  opacity: 0.5;
}