.fab {
  @media (min-width: 767.98px) {
    right: 30px;
  }

  @media (max-width: 767.98px) {
    bottom: 10px;
  }

  &Button {
    @media (max-width: 767.98px) {
      width: 50px;
      height: 50px;
    }
  }
}