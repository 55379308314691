.container{
  width: 100%;
  max-width: 1200px;
  margin: 0 auto ;
  position: relative;

  &Sm{
    @media (min-width: 575.98px) {
      max-width: 540px;
    }
  }
  &Md{
    @media (min-width: 767.98px) {
      max-width: 720px;
    }
  }
  &Lg{
    @media (min-width: 991.98px) {
      max-width: 960px;
    }
  }
  &Xl{
    @media (min-width: 1999.98px) {
      max-width: 1200px;
    }
  }

  &Max{
    &Sm{
      max-width: 540px;
    }

    &Md{
      max-width: 720px;
    }

    &Lg{
      max-width: 960px;
    }
    &Md{
      max-width: 1200px;
    }
  }
}