.shippingZone {
  &__name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: 600;
    font-size: 12px;

    @media (max-width: 340px) {
      max-width: 70px;
    }

    @media (min-width: 768px) {
      font-size: 16px;
    }
  }
}

ion-select::part(icon) {
  display: none;
}
