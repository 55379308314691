.product{

  &Wrapper{
    display: flex;
  }

  &Content{
    cursor: pointer;
    .buttons {
      align-items: center;
      flex-wrap: nowrap;
      span {
        margin: 0;
        padding: 0 10px;
      }
    }

    &Img{
      width: 100%;
      max-width: 100%;
      > img{
        width: 100%;
      }
    }

    &Desc{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

  }

  &Card{
    &Price {
      background-color: white;

      > span {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
        justify-content: flex-end;

        ins {
          // price with sale
          display: block;
          overflow: unset;
          padding-right: 1px;
          color: var(--ion-color-danger);

          bdi {
            line-height: 1;
            font-size: 16px;
          }

          span {
            margin: 0;
          }
        }

        del {
          // price for sale product without sale
          display: block;
          // position: absolute;
          top: 0;

          bdi {
            line-height: 1;
            font-size: 12px;
          }
        }
      }
    }
  }
}