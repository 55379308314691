.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .button {
    --border-radius: var(--border-radius-button-value);
    --background: #efecec;
    --color: #333e48;
    --box-shadow: none;
    --padding-bottom: 15px;
    --padding-top: 15px;
    text-transform: none;
    --transition: all 0.2s;
    margin: 0;
    height: 50px;

    &--last {
      margin-bottom: 0;
    }

    ion-icon {
      margin-left: 7px;
    }

    &:active, &:hover {
      --background: var(--ion-color-primary);
      --color: var(--ion-color-primary-contrast);
    }

    &--primary {
      --background: var(--ion-color-primary);
    }

    &--dark {
      --background: black;
      --color: white;

      &:active {
        --background: white;
        --color: #333e48;
      }
    }
  }

  .myAccount {
    &Container {
      padding: 15px;
      height: auto;
      min-height: 80vh;
      flex-grow: 1;
    }

    &Buttons {
      justify-content: flex-end;

      @media (min-width: 576px) {
        justify-content: center;
      }
    }

    &Form {
      &__label {
        display: block;
        margin-bottom: 10px !important;
      }

      &--password {
        --padding-end: 50px;
      }

      &Input {
        border: 1px solid #ddd;
        border-radius: 22px;
        --padding-top: 12px;
        --padding-bottom: 12px;
        --padding-start: 17px;
        --padding-end: 17px;
        transition: border-color 0.2s;
        color: #333e48;

        &:focus-within,
        &:active {
          border-color: #66afe9;
          transition: border-color 0.2s;
        }
      }

      &Checkbox {
        --inner-padding-end: 0;
        margin-bottom: 20px;

        &--noMargin {
          margin-bottom: 0;
        }
      }

      &Select {
        width: 100%;
      }

      &SelectItem {
        --border-radius: 20px;
      }

      &Title {
        font-size: 22px;
        color: #333e48;
        border-bottom: 1px solid #ddd;
        padding-bottom: 10px;
        position: relative;

        &::after {
          content: "";
          border-bottom: 2px solid var(--ion-color-primary);
          width: 100%;
          position: absolute;
          bottom: -1px;
          display: block;
        }
      }

      &_description {
        color: #333e48;
        font-size: 14px;
      }

      &Alert {
        bottom: -51px;
      }
    }
  }

  .referral {
    display: flex;

    &__item {
      position: relative;
      margin-bottom: 0;
      --inner-padding-end: 0px;
    }

    &__input {
      --padding-end: 55px !important;
    }

    &__iconWrapper {
      position: absolute;
      z-index: 10;
      cursor: pointer;
      top: 30%;
      right: 25px;
      font-size: 20px;

      @media (min-width: 575.98px) {
        font-size: 25px;
        top: 23%;
      }
    }
  }
}

.maskedInput {
  width: 100%;
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 22px;
  height: 44px;
  padding: 3px 8px 8px 16px;

  &:focus-visible {
    outline: 0;
  }
}

.checkboxItem {
  --min-height: 0;
  --padding-start: 8px;
  --padding-top: 4px;
  --padding-bottom: 4px;

  &_checkbox {
    width: 17px;
    height: 17px;
    margin: 0 5px;
  }

  &_label {
    margin: 0;
    font-size: 14px;
    color: #333e48;
  }
}

.earnedPoints {
  margin-bottom: 20px;
  font-size: 16px;

  &__title {
    font-weight: bold;
  }
}

.skeleton {
  &_item {
    margin-left: 0;
  }
}

.barcodeCol > svg {
  margin: 0 auto;
}