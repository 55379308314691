.modal {
  --height: 340px;
  --width: 90%;

  .modal-wrapper {
    border-radius: 20px;
  }

  .ion-page {
    padding: 20px;
  }

  h5 {
    text-align: center;
    background: rgb(64, 179, 64);
    color: white;
    margin: 0;
    padding: 10px;
  }

  button {
    border-radius: 20px;
    height: 30px;
  }
}

.required {
  &::after {
    content: 'Такая почта не найдена';
    border-top: 1px solid var(--ion-color-danger);
    font-size: 14px;
    position: relative;
    display: block;
    color: var(--ion-color-danger);
  }
}

.forgotPassword {
  &Content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &Form {
    @media (max-width: 767.98px) {
      height: 100%;
    }
    padding: 0 15px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}