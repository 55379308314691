.addressModalContent {

  &::part(scroll) {
    overflow-y: hidden;
  }

  &-scrollVisible {
    &::part(scroll) {
      overflow-y: auto;
    }
  }
}
