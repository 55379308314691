.attributes {
  &Card {
    height: auto !important;
    box-shadow: none !important;
    border-radius: 0;
    margin: 0 5px;

    &Header {
      padding: 0 0 5px 0;
    }

    &Content {
      padding: 0;
    }

    &Span {
      display: inline-block;
      margin-right: 3px;
    }

    &Badge {
      cursor: pointer;
    }
  }
}

.attribute {
  &IsDisabled {
    opacity: 0.4;
  }
}

.disabled {
  opacity: 0.5;
  cursor: default;
}