.grid-cart {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ion-row {
    ion-col {
      text-align: center;
      ion-icon {
        font-size: 70px;
        color: lightgrey;
      }
      ion-button {
        height: 30px;
      }
      h4 {
        font-size: 16px;
        font-weight: bold;
        margin-top: 2px;
      }
      h5 {
        font-size: 14px;
        color: gray;
        margin-top: 0;
      }
    }
  }
}

.quantity-icon {
  font-size: 7vw;
  vertical-align: -webkit-baseline-middle;
}
.quantity {
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 800;
  margin: auto;
}

.bottom-line {
  border-bottom: solid lightgray;
  border-width: 0.2px;
}
.top-line {
  border-top: solid lightgray;
  border-width: 0.2px;
}
