
.loginContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login{
  &Form{
    @media (max-width:767.98px) {
      height: 100%;
    }

    padding: 0 15px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
