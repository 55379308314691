ion-content{
  --padding-top: 0px;
}

@media (min-width: 768px) {
  :global {
    .ion-page {
      padding-bottom: 0;
    }
  }
}

.homePage{
  overflow: hidden;
}
