.counter{

  &Backdrop{
    z-index: 2000;
    background: rgba(255, 255, 255, 0.8);
    bottom: 80px;
    height: 80px;
    padding: 10px;
    left: 0;
    right: 0;

    @media (min-width: 768px) {
      padding: 10px 0;
    }
  }

  &Wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--ion-color-secondary);
    height: 60px;
    border-radius: 12px;
    padding: 5px;
  }

  &Price{
    color: var(--ion-color-secondary-contrast);
    margin: 4px 8px;
    &Span{
      margin: 0 2px;
      display: block;
      >span{
        display: flex;
        flex-direction: column-reverse;
        ins{
          text-decoration: none;
          font-size: 20px;
        }
        del{
          font-size: 12px;
        }
        >span{
          font-size: 24px;
        }
      }
    }
  }

  &Quantity{
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    font-size: 22px;
    width: 120px;
    border-radius: 12px;
    padding: 0 17px;
    &Icon{
      color: var(--ion-color-secondary-contrast);
      padding: 8px;
    }

    &Button{
      color: black;
    }
  }

  &Empty{
    padding: 10px;
    font-size: 30px;
    border-left: 1px solid white;
    cursor: pointer;
  }
}

.disabled {
  cursor: default;
  opacity: 0.3;
  & > * {
    cursor: default;
  }
}