.continue {
  &Toolbar {
    --border-style: none;
    --ion-toolbar-background: white;
    // box-shadow: 0 0 30px 0 rgba(169, 165, 153, 0.15);

    &Button {
      --border-raduis: 12px;
      margin: 5px 10px;
    }

    @media (min-width: 768px) {
      box-shadow: none;
      --background: transparent;
      --border-width: 0px;
      --padding-top: 0px;
      --padding-end: 0px;
      --padding-start: 0px;
      --padding-bottom: 0px;

      &Wrapper {
        --background: transparent;
      }

      &Button {
        margin: 0;
      }
    }
  }
}
