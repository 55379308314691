.rating {
  &Item {
  }

  ion-icon {
    font-size: 28px;
    color: gray;
  }
  &Title {
    margin-bottom: 6px;
    text-align: start;
    color: var(--ion-color-primary);
    font-size: 14px;
    margin-top: 6px;
  }

  &Stars {

    &Outer {
      display: inline-block;
      position: relative;
      font-size: 25px;


      &::before {
        content: "\2606\2606\2606\2606\2606";
        color: #ccc;
      }
    }

    &Inner {

    &Inner {
      font-size: 25px;
      position: absolute;
      top: 0;
      left: 0;
      white-space: nowrap;
      overflow: hidden;


      &::before {
        content: "\2605\2605\2605\2605\2605";
        color: #f8ce0b;
      }
    }
  }
}

.productInfoSegment {
  width: 100%;
  background: white;
  margin-bottom: 20px;

  .productInfoSegmentButtons {
    height: 40px;
    margin: 10px 0;
    padding: 0 10px;
    background: rgb(236, 236, 236);
    background: rgb(236, 236, 236);
    border-radius: 0;

    ion-segment-button {

    ion-segment-button {
      --border-radius: 8px;
      --color-checked: var(--ion-color-primary-contrast);
      --indicator-color: var(--ion-color-primary);
    }}
  }}

  ion-grid {
  ion-grid {
    margin: 20px 10px;
  }
}

.productDescription {
  &Title {
    background: var(--ion-color-primary);
    margin: 20px 15px
  }
}}

.productInfoDesc {
  padding-block-start: 1rem;
}