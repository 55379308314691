.dateTimeButton {
  width: 100%;

  &::part(native) {
    background: none;
    padding: 0;
    width: 100%;
    text-align: start;
  }
}
