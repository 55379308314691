.shadow {
  background: transparent;
  margin: 10px 10px 0 0;
  box-shadow: -2px 20px 25px rgba(158, 152, 135, 0.1);
  border-radius: var(--border-radius-product-value);
  height: calc(100% - 10px);
  width: 100%;
}

.see-all {
  height: 100% !important;
  width: 100%;
  margin: 0;
  color: white;
  font-size: 26px;

  &-wrapper {
    height: 100%;
  }

  &-slide {
    height: auto;
  }
}

.ion-slide-max-width {
  width: 190px !important;
  min-width: 190px;
  max-width: 190px !important;
}

.ionSliderWrapper {
  overflow: visible;

  :global(.swiper-pagination-bullets) {
    bottom: -30px;
  }
}

.product-slide {
  touch-action: pan-y;
}

.slider__item {
  margin-right: 10px;
}