[title="ya-geolocation-button"] {
    border-radius: 50% !important;
    padding: 0 !important;
    width: 44px !important;
    height: 44px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    box-shadow: 0 8px 24px rgba(192, 195, 200, 0.5) !important;
}

[title="ya-back-button"] {
    background-color: white;
    margin-top: var(--ion-safe-area-top, 0);
    box-shadow: 0 8px 24px rgba(192, 195, 200, 0.5) !important;
    width: 41px;
    border-radius: 50%;
    height: 41px;
    cursor: pointer;
    pointer-events: none;
}

[title="ya-geolocation-button"] > *:first-child,
[title="ya-back-button"] > *:first-child {
    position: relative !important;
}

.placemark_layout_container {
    position: relative;
    font-size: 13px;
    text-align: center;
    font-weight: bold;
}

/* Макет метки с "хвостиком" */
.polygon_layout {
    background: #ffffff;
    border: 2px solid #444BFF;
    border-radius: 4px;
    position: absolute;
    padding: 2px;
    /*left: -28px;*/
    /*top: -76px;*/
    transform: translate(-49%, -124%);
    color: black;
    font-weight: normal;
}

.polygon_layout:after, .polygon_layout:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
}

.polygon_layout:after {
    border-top-color: white;
    border-width: 8px;
    margin-left: -8px;
}

.polygon_layout:before {
    border-top-color: #444BFF;
    border-width: 10px;
    margin-left: -10px;
}

.polygon_layout.active {
    background: black;
    border: 2px solid black;
    color: white;
    z-index: 2;
}

.polygon_layout.active:after {
    border-top-color: black;
}

.polygon_layout.active:before {
    border-top-color: black;
}
