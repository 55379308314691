.auth-code-modal {
  &::part(content) {
    width: 270px;
    height: 345px;
    --max-width: 270px;
    --max-height: 345px;
    border-radius: 10px;
  }
}


// -----------------------

.head {
    -webkit-padding-start: 16px;
    padding-inline-start: 16px;
    -webkit-padding-end: 16px;
    padding-inline-end: 16px;
    padding: 12px 16px 7px;
    text-align: center;

    &__title {
        color: var(--ion-text-color, #000);
        font-size: 17px;
        font-weight: 600;
        margin: 8px 0 0;
        padding: 0;
    }
}


.message {
    max-height: 240px;
    -webkit-padding-start: 16px;
    padding-inline-start: 16px;
    -webkit-padding-end: 16px;
    padding-inline-end: 16px;
    padding: 0 16px 21px;
    color: var(--ion-text-color, #000);
    font-size: 13px;
    text-align: center;
}



.input {
    border-radius: 4px;
    margin-top: 10px;
    padding: 6px;
    border: 0.55px solid var(--ion-color-step-250, #bfbfbf);
    background-color: var(--ion-background-color, #fff);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &__wrapper {
        padding: 0 16px 21px;
        color: var(--ion-text-color, #000);
        font-size: 13px;
        text-align: center;
        -webkit-padding-start: 16px;
        padding-inline-start: 16px;
        -webkit-padding-end: 16px;
        padding-inline-end: 16px;
    }
}

.buttons {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;

    &__item {
        margin: 0;
        flex: 1 1 auto;
        min-width: 50%;
        height: 44px;
        border-top: 0.55px solid rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.2);
        border-right: 0.55px solid rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.2);
        --background: tranparent;
        background-color: transparent;
        color: var(--ion-color-primary, #3880ff);
        font-size: 17px;
        overflow: hidden;
        outline: none;
        line-height: 20px;
        --background-hover: var(--ion-color-light-shade);
        border-radius: 0;
        --border-radius: 0;

    }
}

.error-message {
    color: var(--ion-color-danger);
    padding-top: 10px;
    padding-right: 10px;
}


.cancel {
    color: var(--ion-color-danger);
}

.disabled {
    //color: var(--ion-color-medium-shade);
    opacity: 0.5;
    background: #d5d4d4;
}

.continue, .try-again {
    color: var(--ion-color-dark);
}

.try-again {
    &__span {
        padding: 0 50px;
        position: relative;
    }

    &__timer {
        position: absolute;
        right: 15px;
    }
}