ion-action-sheet.shipping-zones-action-sheet .action-sheet-group {
  background: var(--ion-color-dark-contrast) !important;
}

ion-action-sheet.shipping-zones-action-sheet .action-sheet-button,
ion-action-sheet.shipping-zones-action-sheet .action-sheet-button.ion-focused {
  color: var(--ion-color-light-contrast) !important;
}

ion-action-sheet.shipping-zones-action-sheet ion-backdrop {
  opacity: 0.6;
}

@media (any-hover: hover) {
  ion-action-sheet.shipping-zones-action-sheet .action-sheet-button.sc-ion-action-sheet-ios:hover {
    color: var(--ion-color-light-contrast) !important;
  }
}
