.inputItem {
    &::after {
        content: attr(data-invalid-text);
        font-size: 14px;
        position: relative;
        display: block;
        color: var(--ion-color-danger);
        transition: max-height 0.3s;
        margin-top: 2px;
        max-height: 0;
    }

    &__required {
        --border-color: var(--ion-color-danger);

        &::after {
            transition: max-height 0.3s;
            max-height: 50px;
        }
    }
}

.resetPassword{
    &__content{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__container{
        @media (max-width:767.98px) {
            height: 100%;
        }
        padding: 15px 15px 0 15px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}