.common {

  &Item {
    position: relative;
    overflow: visible;
    --min-height: 0px !important;
    --background-hover: none;
    --background-activated: none;
    --background-focused: none;
    margin-bottom: 5px;
    --inner-padding-end: 0px;
    z-index: 2;
  }

  &Label {
    font-size: 14px !important;
    color: #333e48;
    font-weight: bold;
    white-space: break-spaces !important;
    display: block;
    margin-bottom: 10px !important;
    padding-left: 5px;
  }

  &Element {
    border: 1px solid #ddd;
    border-radius: var(--border-radius-input-value);
    --padding-top: 12px !important;
    --padding-bottom: 12px !important;
    --padding-start: 17px !important;
    --padding-end: 17px !important;
    transition: border-color 0.2s;
    color: #333e48;

    &:focus-within,
    &:active {
      border-color: #66afe9;
      transition: border-color 0.2s;
    }

    & > input {
      &:-webkit-autofill {
        border-radius: var(--border-radius-input-value);
      }
    }

    &MaskedInput {
      width: 100%;

      &:focus-visible {
        outline: 0;
      }
    }

    &Select, &Datetime {
      padding: 12px 17px;
    }

    &Textarea {
      resize: none;
      overflow: scroll;
    }
  }

  &Invalid {
    border: 1px solid var(--ion-color-danger);

    &:focus-within,
    &:active {
      border-color: var(--ion-color-danger);
      transition: border-color 0.2s;
    }
  }

  &ErrorMessage {
    max-height: 100px;
    margin: 5px;
    font-size: 14px;
    color: var(--ion-color-danger);
    overflow: hidden;
    transition: max-height .25s;
    transition-timing-function: ease-in;

    &__collapsed {
      max-height: 0;
      transition-timing-function: ease-out;
    }
  }
}

@media (min-width: 768px) {
  .common {
    &Label, &ErrorMessage {
      font-size: 16px !important;
    }
  }
}