a {
    text-decoration: none;
}

.info {
    text-align: left;
    padding: 10px 0;
}

.contact {
    font-weight: bold;
}