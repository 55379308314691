.irskomDiscountPrice {
  color: var(--ion-color-secondary) !important;
}

.product-component {
  ion-card,
  ion-item {
    // For Customize Badges
    ion-badge {
      top: 0;
      position: absolute;
      z-index: 1;
      border-radius: 2px;
      &:nth-child(2) {
        top: 22px;
      }
    }
    /* Ion Bage For New Badge */
    .new-badge {
      background: none !important;
      width: 150px;
      height: 150px;
      top: 0 !important;
      left: 0 !important;
    }

    #newimage {
      position: absolute;
      z-index: 1;
      left: 0 !important;
      width: 30%;
      top: 0;
    }

    .grid-padding {
      padding-bottom: 0;
    }


    ion-button {
      width: 100%;
      height: 25px;
      --border-radius: 0px;
    }
  }

  // // for product list
  .ion-item {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    ion-thumbnail {
      margin: 0;
      height: 100px;
      width: 100px;
    }
    ion-label {
      margin-top: 0;
      margin-bottom: auto;
      .btn-cart {
        margin-top: 32px;
      }
      .list-heart-icon {
        margin-bottom: 0;
        position: absolute;
        top: 73px;
        right: 10px;
        font-size: 25px;
      }
    }
    .img-div {
      font-size: 12px;
      :nth-child(1) {
        top: 0;
      }
      :nth-child(2) {
        top: 25px;
      }
    }
  }

  .card-add-cart {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 110%;
      height: 102%;
      background-color: white;
      opacity: 0.8;
    }
    ion-icon {
      position: unset;
      margin: auto;
      font-size: 2.5rem;
      color: var(--ion-color-secondary);
      z-index: 10;
    }
  }

  [dir="rtl"] {
    ion-card p {
      text-align: right !important;
    }
  }
}

.skeletonWidth {
  width: 100%;
}

.sktImg {
  width: 96%;
  margin-left: 10px;
}

.sktName {
  width: 80%;
  margin-left: 10px;
}

.badge_new{
  position: absolute;
    z-index: 1;
    height: 45px;
    width: 45px;
}

.textColor{
  color: var(--ion-text-color);
}

.price {

  .woocommerce-Price-amount {
    display: block;
    font-size: 16px;
    font-weight: bold;
  }
  .woocs_price_code {
    display: flex;
  }
  del {
    .woocommerce-Price-amount {
      color: rgba(var(--ion-text-color-rgb), 0.5);
      text-decoration: line-through;
    }
  }
  ins {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-decoration: none;
    .woocommerce-Price-amount {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: var(--ion-color-danger);
      margin-left: 5px;
    }
  }
}