.textArea {
  min-height: 160px;
  border: 1px solid #737373;
}

/* Скрываем скроллбар для Chrome, Safari и Opera */
.textArea::-webkit-scrollbar {
  display: none;
}

/* Скрываем скроллбар для IE, Edge и Firefox */
.textArea {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}