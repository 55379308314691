.custom-products-slider {
  padding: 0 10px;
  position: relative;
  width: 100%;
  //overflow: hidden;
  overflow-x: scroll;
  white-space: nowrap;
  transition: all 0.2s;
  will-change: transform;
  user-select: none;
  cursor: grab;
  padding-bottom: 10px;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.custom-products-slider::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.active-custom-slider {
  cursor: grabbing;
}
