.bannerWrapper {
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
}

ion-skeleton-text {
  margin: 10px;
  border-radius: 12px;
}
