.cart {
  &ContentEmpty {
    min-height: 100%;
    // height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &Grid {
    display: flex;
    position: relative;
  }

  &Wrapper {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    margin-left: auto;
    @media (min-width: 992px) {
      flex-direction: row;
      align-items: flex-start;
    }
  }

  &Products,
  &TotalInfo {
    flex-basis: auto;
  }

  &TotalInfo {
    @media (min-width: 992px) {
      position: relative;
      z-index: 99;
    }
  }
}
