.presentWarning {
  height: 61px;
  top: 162px;
  left: 50%;
  width: 50%;
  // left: 32%;
  // width: 645px;

  @media (min-width: 768px) {
    left: 25%;
    width: 46%;
  }

  @media (min-width: 992px) {
    left: 20%;
    width: 57%;
  }

  @media (min-width: 1200px) {
    left: 21%;
    width: 58.5%;
  }
}

.searchBox {
  display: flex;
  position: relative;
  height: 32px;
  width: 100%;
  padding: 4px;
  background: white;
  border-radius: 18px;

  &__desktop {
    overflow: visible;
  }

  &__common {
    display: flex;
  }

  @media (min-width: 768px) {
    padding: 0;
    height: 41px;
    border-width: 0;
    border-radius: 20px;
  }

  &__input {
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    font-size: 18px;

    &::placeholder {
      font-size: 14px;
      text-align: center;
    }
  }

  &__wrapper {
    position: relative;
    width: 100%;
    padding-left: 15px;
    overflow: hidden;
  }

  &__result {
    z-index: 100;
    background-color: white;
    position: absolute;
    overflow: auto;
    top: 50px;
    bottom: -70vh;
    left: 0;
    right: 5px;
    transition: bottom 2s;
    border: 1px solid var(--ion-color-primary);
    border-radius: 0 0 10px 10px;

    @media (min-width: 768px) {
      top: 45px;
      bottom: -50vh;
      right: 64px;
    }
  }

  &__item {
    max-height: 85px;

    &:hover {
      cursor: pointer;
      background-color: rgba(220, 220, 220, 0.5);
    }

    &::part(native) {
      max-height: 85px;
    }
  }

  &__label {
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* start showing ellipsis when 3rd line is reached */
    white-space: pre-wrap !important; /* let the text wrap preserving spaces */
}

  &__itemLoad {
    background-color: #d7d7d7;
    // background-color: #333e48;
  }

  &__showMoreProducts {
    width: 100%;
    height: 40px;
    font-size: 17px;
    margin: 5px auto;
    display: block;
    font-weight: 500;

    &:hover {
      background-color: rgba(189, 189, 189, 0.362);
    }
  }
}

.searchIcon,
.searchSpinner {
  position: absolute;
  z-index: 100;

  &--md {
    @media (min-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      right: 0;
      background-color: var(--ion-toolbar-color);
      color: #fff;
      text-align: center;
      border-radius: 0 20px 20px 0;
      padding: 9px 20px;
    }
  }
}

.cleanSearch {
  position: absolute;
  z-index: 100;

  &--md {
    @media (min-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      bottom: 0;
      right: 75px;
    }
  }
}

.cleanSearch,
.searchIcon {
  cursor: pointer;
}

.searchSpinner {
  width: 64px;
  height: 41px;

  &--md {
    padding: 6.5px 20px;
  }
}

.searchIcon {
  &--sm {
    @media (max-width: 767.98px) {
      width: 22px;
      height: 18px;
      top: 2px;
      right: 5px;
    }
  }

  &__icon {
    cursor: pointer;

    &--sm {
      @media (max-width: 767.98px) {
        width: 22px;
        height: 18px;
        color: rgba(44, 44, 44, 0.2);
      }
    }
    
  }
}

.cleanSearch {
  &--sm {
    @media (max-width: 767.98px) {
      width: 26px;
      height: 26px;
      top: 0;
      right: 5px;
    }
  }

  &__icon {
    cursor: pointer;

    &--sm {
      @media (max-width: 767.98px) {
        width: 26px;
        height: 26px;
        color: rgba(44, 44, 44, 0.2);
      }
    }
  }
}

.product {
  &__info {
    padding-right: 10px;
    padding-top: 10px;
    text-align: right;

    &--error {
      color: var(--ion-color-danger);
    }
  }
}