.contacts {
  text-align: center;
  color: var(--ion-color-primary-contrast);
  &__item {
    display: flex;
    align-items: center;
    background-color: var(--ion-color-primary);
    border-radius: 8px;
    height: 80px;
    margin: 10px;
    .contacts__icon {
      width: 20%;
      margin: 0 auto;
    }
    .contacts__text {
      color: var(--ion-color-primary-contrast);
      text-align: left;
      width: 70%;
    }
  }
  &__social {
    display: block;
  }
}

.appVersionLabel {
  margin: 5px;
  text-align: end;
  color: #bbb;
  font-size: 14px;
}
