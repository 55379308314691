.toolbar {
  background-color: var(--ion-color-primary);
  border-radius: var(--border-radius-toolbar-value);
  padding: 0;
  display: flex;
  align-items: flex-end;
}

.products {
  &Container {
    padding: 0 0 30px 0;
  }
}

.searchBox {
  display: flex;
  position: relative;
  padding: 4px;
  height: 42px;
  background: white;
  border: 2px solid var(--ion-color-secondary);
  border-radius: var(--border-radius-value);
  margin: 30px 8px 9px 8px;
  align-items: center;
  padding-left: 15px;

  @media (min-width: 768px) {
    padding: 0;
    border-width: 0;
    border-radius: 20px;
  }

  &__input {
    color: #222;
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    font-size: 18px;

    &::placeholder {
      color: var(--ion-color-medium-shade);
      font-size: 14px;
      text-align: center;
    }
  }

  &__button {
    padding: 15px;
    cursor: pointer;
    &:hover {
      cursor: pointer;
      background-color: rgba(220, 220, 220, 0.5);
    }
  }

  &__backButton {
    margin-left: 50px;
  }

  &__result {
    position: absolute;
    overflow: auto;
    top: 50px;
    bottom: -70vh;
    left: 0;
    right: 5px;
    transition: bottom 2s;
    border: 2px solid var(--ion-color-primary);

    @media (min-width: 768px) {
      top: 44px;
      bottom: -50vh;
      right: 64px;
    }
  }

  &__item {
    border-bottom: 1px solid var(--ion-color-primary);

    &:hover {
      cursor: pointer;
      background-color: rgba(220, 220, 220, 0.4);
    }
  }
}

.cleanSearch,
.searchIcon {
  &--md {
    @media (min-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      right: 0;
      background-color: #333e48;
      color: #fff;
      text-align: center;
      border-radius: 0 20px 20px 0;
      padding: 9px 20px;
    }
  }
}

.searchIcon {
  &--sm {
    @media (max-width: 767.98px) {
      width: 22px;
      height: 18px;
      top: 6px;
      right: 5px;
    }
  }
  &__icon {
    &--sm {
      @media (max-width: 767.98px) {
        width: 22px;
        height: 18px;
        color: rgba(44, 44, 44, 0.2);
      }
    }
    &--md {
      @media (min-width: 768px) {
      }
    }
  }
}
.cleanSearch {
  display: flex;
  align-items: center;

  &--sm {
    @media (max-width: 767.98px) {
      top: 0;
      bottom: 0;
      right: 33px;
    }
  }
  &__icon {
    &--sm {
      @media (max-width: 767.98px) {
        width: 26px;
        height: 26px;
        color: rgba(44, 44, 44, 0.2);
      }
    }
    &--md {
      @media (min-width: 768px) {
      }
    }
  }
}

.searchBox__buttons {
  position: absolute;
  left: -50px;
  top: 0;
  bottom: 0;
}

.submit-button {
  background: transparent;
}
