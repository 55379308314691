.noReviewsRow {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60%;
  color: #3e3e3e3e;

  &_icon {
    font-size: 75px;
  }
}