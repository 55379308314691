.productList {
  overflow: visible;

  &Wrapper {
    align-items: center;
    padding-bottom: 6px;
    border-bottom: 1px solid #ddd;

    .productListTitle {
      margin: 12px 0 5px 0;
      font-weight: bold;
      text-transform: none;
      font-size: 18px;
      color: var(--ion-color-sections-value);
    }
  }

  &SeeAll {
    cursor: pointer;
    border-bottom: 1px solid #cdcdcd;
    margin: 12px 0 5px 0;
  }

  &Bookmark {
    display: none;
  }
}

.wrapper {
  @media (min-width: 768px) {
    margin-bottom: 61px;
  }
}
