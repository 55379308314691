.product {
  &__item {
    --padding-bottom: 10px;
  }

  &__title {
    padding: 0 20px;
  }
  
  &__img{
    object-fit: contain;
    cursor: pointer;
  }
}