$baseFontSize: 16;
@function rem($pixels, $context: $baseFontSize) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1rem;
}

$grid-columns: 12;
$grid-gutter-width: rem(20);

$modal-md: rem(500);
$modal-content-border-width: 0;
$modal-content-border-radius: 0;
$modal-content-box-shadow-xs: none;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1296px,
);

$main-text-color: #000;
$white: #fefefe;
$black: #21212c;
$blue: #0511a5;
$red: #e31b73;
$line: #e0e1ea;
$text: #686a81;
$gray: #b9bac7;
$hover-red: #cc1063;

$shadow: 0px 1px rem(20) rgba(36, 36, 46, 0.1);

$base-font-size: 16px;
$base-ff: "Roboto", sans-serif;

body.secondTheme {
  --ion-color-primary: #0511a5;
  --ion-color-primary-rgb: 227, 27, 115;
  --ion-color-primary-contrast: #e0e1ea;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #040f91;
  --ion-color-primary-tint: #1e29ae;

  --ion-color-secondary: #e31b73;
  --ion-color-secondary-rgb: 5, 17, 165;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #c81865;
  --ion-color-secondary-tint: #e63281;

  --ion-color-tertiary: #21212c;
  --ion-color-tertiary-rgb: 33, 33, 44;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #1d1d27;
  --ion-color-tertiary-tint: #373741;

  --ion-color-success: #00d933;
  --ion-color-success-rgb: 0, 217, 51;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #00bf2d;
  --ion-color-success-tint: #1add47;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-medium: #b9bac7;
  --ion-color-medium-rgb: 185, 186, 199;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #a3a4af;
  --ion-color-medium-tint: #c0c1cd;

  --ion-color-light: #e0e1ea;
  --ion-color-light-rgb: 224, 225, 234;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #c5c6ce;
  --ion-color-light-tint: #e3e4ec;

  // FOR CUSTOM ELECTRIC COLOR
  --ion-color-electric: #4e2aff;
  --ion-color-electric-rgb: 78, 42, 255;
  --ion-color-electric-contrast: #ffffff;
  --ion-color-electric-contrast-rgb: 255, 255, 255;
  --ion-color-electric-shade: #4525e0;
  --ion-color-electric-tint: #603fff;

  // FOR CUSTOM HELIOTROPE COLOR
  --ion-color-heliotrope: #e83bef;
  --ion-color-heliotrope-rgb: 232, 59, 239;
  --ion-color-heliotrope-contrast: #fefefe;
  --ion-color-heliotrope-contrast-rgb: 0, 0, 0;
  --ion-color-heliotrope-shade: #cc34d2;
  --ion-color-heliotrope-tint: #ea4ff1;

  --ion-toolbar-color: var(--ion-color-secondary);
  --ion-icon-background-color: var(--ion-color-secondary);
  --ion-border-color: var(--ion-color-secondary);
  --border-radius-value: 10px;
  --border-radius-product-value: 12px;
  --border-radius-card-value: 4px;
  --border-radius-toolbar-value: 0px 0px 12px 12px;
  --border-radius-input-value: 6px;
  --border-radius-button-value: 6px;
  --transform-scale-value: scale(1.5);
  --transform-scale-value-category: scale(2);
  --ion-color-new-product: var(--ion-color-heliotrope);
  --ion-color-sections-value: #21212c;
  --header-logo-height: 60px;
  --header-logo-width: 60px;
  --header-logo-height-minimized: 40px;
  --header-logo-width-minimized: 40px;
  --category-icon-size: 90px;
  --category-icon-padding: 20px 20px 15px 20px;
  --home-page-category-size: 64px;

  ion-button {
    --border-radius: 6px;
  }
  --toolbal-background-color-value: #fff;
  --ion-toolbar-background: #e0e1ea;
  --ion-card-border-value: 1px solid #e0e1ea;
  --border-product-new-list: 1px solid #e0e1ea;
  --border-product-new-list-bottom: none;
  --border-radius-new-list-value: 12px;
  --border-shadow-new-list-product: 4px 3px 3px rgba(36, 36, 46, 0.1);
}

// FOR CUSTOM ELECTRIC COLOR
.ion-color-electric {
  --ion-color-base: var(--ion-color-electric);
  --ion-color-base-rgb: var(--ion-color-electric-rgb);
  --ion-color-contrast: var(--ion-color-electric-contrast);
  --ion-color-contrast-rgb: var(--ion-color-electric-contrast-rgb);
  --ion-color-shade: var(--ion-color-electric-shade);
  --ion-color-tint: var(--ion-color-electric-tint);
}

// FOR CUSTOM HELIOTROPE COLOR
.ion-color-heliotrope {
  --ion-color-base: var(--ion-color-heliotrope);
  --ion-color-base-rgb: var(--ion-color-heliotrope-rgb);
  --ion-color-contrast: var(--ion-color-heliotrope-contrast);
  --ion-color-contrast-rgb: var(--ion-color-heliotrope-contrast-rgb);
  --ion-color-shade: var(--ion-color-heliotrope-shade);
  --ion-color-tint: var(--ion-color-heliotrope-tint);
}

:root {
  font-size: 16px;
  --accent-color: #dddddd;
  --heading-font-size: 0.8rem;
  --sub-heading-font-size: 0.6875rem;
  --ion-color-facebook: #3b5998;
  --ion-color-star: #d9d027;
  --ion-color-gray: gray;
  --ion-margin: 10px;
  --ion-padding: 10px;
}
